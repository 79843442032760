@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.vetChat {
  @include flexbox($ai: stretch, $jc: space-between);
  flex: 1;

  .infoPanel {
    background-color: $gray246;
    flex: 1;
    height: calc(100vh - 6rem);
    max-width: 35.5rem;

    > div > div {
      &:first-of-type {
        background-color: $gray246;
      }
    }
  }
}

.emptyScreen {
  @include flexbox($ai: center, $jc: center);
  @include padx(20px);
  background: {
    repeat: no-repeat;
    position: {
      x: 50%;
      y: calc(100% + 100px);
    }
    size: 1000px;
  }
  flex: 1;
  overflow: hidden;

  .inner {
    @include flexbox($fd: column, $jc: center, $gap: 16px);
    @include pady(35px);
    background-color: rgb(255 255 255 / 55%);
    width: 100%;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
  p {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 150px;
    text-align: center;
  }
}