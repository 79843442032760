@use 'sass:color';

@import '../scss/abstracts/variables';
@import '../scss/abstracts/mixins';

.main {
  @include flexbox($fd: column, $gap: 98px);
  background-color: $white;
  padding-bottom: 35px;

  .header {
    @include fixed($top: 0, $right: 0, $left: 0);
    background-color: $navy;
    height: 70px;
    width: 100%;
  }

  .formBlock {
    @include flexbox($fd: column, $gap: 30px);
    margin-top: 130px;
    max-width: 352px;
    width: 98%;

    .title {
      color: $navy;
      font: {
        family: $inter;
        size: 24px;
        weight: 500;
      }
      letter-spacing: 0.25px;
      line-height: 34px;
    }

    Form {
      @include flexbox($ai: flex-start, $fd: column, $gap: 24px);
      margin-top: 5px;
      width: 100%;

      > div {
        margin-bottom: 0;

        > div > div {
          gap: 2px;

          label {
            color: $gray56;
            font: {
              size: 14px;
              weight: 400;
            }
            line-height: 20px;

            > span {
              color: $asterisk;
            }
          }

          input {
            @include border-rad(4px);
            @include padxy(8px);
            background-color: $white;
            border-color: $gray217;
            color: #838383;
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
          }
        }
      }

      .actions {
        @include flexbox($gap: 24px, $jc: space-between);
        width: 100%;

        > button {
          @include pady(8px);
          background-color: $medBlue!important;
          border: 3px solid $medBlue!important;
          flex: 1;
          font: {
            size: 18px;
            weight: 600;
          }
          height: auto;

          &:hover {
            background-color: scale-color($color: $medBlue, $blackness: 50%)!important;
          }
          &:active {
            background-color: $white!important;
            color: $medBlue!important;
          }
        }
      }
    }
  }
  
  section.container {
    gap: 3rem;
    margin: auto;
    max-width: 1200px;
  }
}
