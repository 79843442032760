// file: client/src/components/CustomInput/styles.module.scss

// @import '../../scss/imports';
@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.container {
  width: 100%;

  input,textarea,select {
    width: 100%;
  }

  .message {
      @include font( $size: 12px, $weight: 600 );
      display: block;
      margin-top: 0.5rem;
  }

  label {
    @include font( $size: 12px, $weight: 700 );

    @include media(1100px) {
      @include font( $weight: 700 );
    }

    margin-bottom: 0.5rem!important;
  }

  .errorText { color: $red; }

  .normSpan {
    @include font( $inter, 12px, 600 );
    color: $gray182;
  }

  .options {
    padding: 8px 22px;
  }

  .options:hover {
    background-color: gray;
    cursor: pointer;
  }

  .dropdown {
    background-color: white;
    position: absolute;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.15);
  }

  &.error {
    input,textarea,select {
      border-color: $red !important;
    }
  }

  .inputContainer {
    @include flexbox($ai: center, $fw: wrap, $jc: space-between, $gap: 4px);
    
    > label {
      margin-bottom: 0!important;
    }
  }
}
