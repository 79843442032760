// file: client/src/scss/base/_tables.scss

.tableContain {
  @include border-rad( 0.8rem );
  padding: 2rem;

  .producer & { padding: 0; }
  
  table {
    @include bd-bottom( $width: 0.1rem, $color: $gray182 );
    
    thead {
      @include absolute;
      @include square( 0.1rem );
      border: none;
      clip: rect(0 0 0 0);
      margin: -0.1rem;
      overflow: hidden;
      padding: 0;

      @include media(992px) {
        @include square( auto );
        @include static;
        margin: auto;
        overflow: visible;
      }
      
      tr { display: table-row;

        th {
          @include bd-bottom( $width: 0.1rem, $color: $gray182 );
          padding: 0 3rem;
          
          @include media(992px) {
            @include font-size( 10px );
            padding-right: 1rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
          }
          @include media(1200px) {
            @include font-size( 12px );
            padding-right: .5rem;
            padding-left: .5rem;
          }
        }
      }
    }

    tbody { font-weight: 500; }
    
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      --bs-table-accent-bg: $gray246;
      background-color: $gray246;
      
      &:first-of-type {
        @include border-rad( 0.5rem 0.5rem 0 0);
        
        @include media(992px) { @include border-rad( 0 ); }
      }
      &:last-of-type {
        @include border-rad( 0 0 0.5rem 0.5rem );
        // --bs-table-accent-bg: $white;
        // background-color: $white;
        
        @include media(992px) {
          @include border-rad( 0 );
          --bs-table-accent-bg: $gray246;
          background-color: $gray246;
        }
      }
    }

    tr {
    //   @include border( $width: 0.1rem, $color: $gray196 );
    //   @include border-rad( 0.5rem );
    //   @include box-shadow( 0, 0.1rem, 0.3rem, rgba(0, 0, 0, 0.1) );
        @include relative;
        background-color: $gray246;
        display: none;
        margin-bottom: 1.5rem;

        @include media(992px) {
            @include border-rad( 0 );
            background-color: transparent;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
        }

        &::before {
            @include absolute( $top: 0, $bottom: 0, $left: 0, $z: 0 );
            content: '';
            background-color: rgb(0 0 0 / 2%);
            width: 10rem;

            @include media(992px) { display: none; }
        }
      
        &.show { display: block;

            @include media(992px) { display: table-row; }
        }
        &.clickable-row { cursor: pointer;
            
            &:hover td { background-color: $blueRowHover; }
        }
        
        th,
        td {
            @include media(1300px) {
            @include font-size( 12px );
            }

            &:first-of-type {
            @include media(992px) { padding-left: 2rem; }
            }
            &:last-of-type {
            @include media(992px) { padding-right: 2rem; }
            }
        }

      td {
        @include bd-bottom( $width: 0.1rem, $color: #ddd );
        @include font-size( 8px );
        --bs-table-accent-bg: transparent!important;
        background-color: transparent;
        display: block;
        text-align: right;
        transition: .15s;

        @include media(576px) { @include font-size( 10px ); }
        @include media(992px) {
          @include font-size( 12px );
          border: 0;
          display: table-cell;
          min-height: 5rem;
          padding: 1.2rem 1rem;
          vertical-align: middle;
          
          &.col7  { width: 7%!important; }
          &.col8  { width: 8%!important; }
          &.col10 { width: 10%!important; }
          &.col11 { width: 11%!important; }
          &.col14 { width: 14%!important; }
          &.col25 { width: 25%!important; }
          &.col33 { width: 33%!important; }
        }
        @include media(1200px) { padding: 1.2rem .5rem; }

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;

          @include media(992px) { display: none; }
        }
        &.actions { width: auto;

          &::before { display: none; }
        }        
        &:last-child { border-bottom: 0; }
      }
      
      &:nth-of-type(odd) td { background-color: $gray246; }
    }
    
    button {
      @include font( $inter, 12px, 700 );
      line-height: 100%;
      height: 4rem!important;
  
      @include media(992px) {
        @include font-size( 10px );
        flex: unset;
        height: 2.6rem!important;
        line-height: 100%;
      }
      
      &.devLink,
      &.tableLink { width: auto!important; }    
      &.devLink {
        @include font-size( 12px );
        height: auto!important;
      }
      &.tableLink {
        @include font-size( 10px );
        height: 2.6rem!important;
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important;
        width: 100% !important;
  
        @include media(576px) { width: auto!important; }
      }
      &.priorityUnmark {
        @include media(992px) {
          margin-left: auto;
          width: 10.5rem!important;
        }
      }
      &.pullCowPulled {
        @include media(992px) { width: 7.1rem!important; }
      }
      &.liveView {
        @include media(992px) { width: 7.6rem!important; }
      }
    }
  }

  #mainContent.admin & {
    .actions {
      margin-top: 2rem;
      
      button {
        @include font-size( 14px );
        height: auto!important;
        line-height: 3.4rem!important;
        padding: 0 1rem!important;
        width: auto!important;
      }
    }
  }
}
