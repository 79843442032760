@import '../../../styles/imports';
.buttonContainer {
    display: flex;
    flex-wrap: wrap; // Allows wrapping to multiple lines if needed
    row-gap: 10px; // Vertical gap
    column-gap: 15px; // Horizontal gap
  }
.button {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .regularButton {
    background-color: #3E9CDB;
    color: white;
    border: none;
  
    &:hover {
      background-color: #337FB3;
      color: white;
    }
  
    @media (max-width: 576px) {
      width: 100% !important;
      order: 1;
    }
  }
  
  .ghostButton {
    background-color: transparent;
    color: #337FB3;
    border: 1px solid #D0D5DD;
  
    &:hover {
      background-color: #337FB3;
      color: white;
    }
  
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  div a {
    @media (max-width: 576px) {
      width: 100%;
      order: 2;
    }
  }