// home hero styles

@import '../../../styles/imports';

#hero {
  background-color: rgba(255, 255, 255, 1);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  min-height: 350px;
  overflow: hidden;
  padding: 30rem 0;
  width: 100%;
  .innerWrapper {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto; // Center the inner wrapper
    padding: 0 15px; // Optional: Add some padding for better spacing
  }
  @media (max-width: 996px) {
    background-image: none !important; // Unset the background image at 992px and smaller

  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1); // Adjust the color and opacity as needed
    clip-path: polygon(25% 0%, 56% 0, 50% 100%, 26% 100%);
    z-index: 20;
    @media (max-width: 992px) {

        display: none;
    }
        


  }
  .animatedText {
    opacity: 0;
    transform: translateY(70px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  .inView {
    opacity: 1;
    transform: translateY(0);
  }
  .buttonContainer {
    display: flex;
    flex-wrap: wrap; 
    row-gap: 15px; 
    column-gap: 15px; 
    padding-top: 3rem;
  }
  
  @include media(576px) { padding: 3rem; }
  @include media(768px) { min-height: 500px; }
  @include media(992px) {
    min-height: 610px;
    padding: 0rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }  
  &::before {
    // background: linear-gradient(231.92deg, rgba(208, 237, 126, 0.7) 0%, #0D516A 100%);
    mix-blend-mode: multiply;
    opacity: .2;
    right: 0;
    z-index: 1;
  }
  &::after {
    // background: linear-gradient(264.1deg, rgba(0, 0, 0, 0) 4.69%, rgba(0, 0, 0, 0.59) 95.31%);
    max-width: 742px;
    width: 100%;
    z-index: 2;
    @include media(768px) { width: 75%; }
  }

  .content {
    z-index: 30;
    position: relative;
  
    h1 {
      padding-top: 60px;
      color: $black;
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 120%;
  

      @include media(576px) { font-size: 5rem; }
      @include media(768px) { font-size: 6rem; }
      
      &::after {
        // background: linear-gradient(270deg, #C4E36E 0%, #027BA7 100%);
        background-color: $white;
        content: '';
        height: 0px;
        max-width: 657px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
    }
    
    p {
      color: $black;
      font-family: $inter;
      font-size: 1.80rem;
      font-weight: 400;
      line-height: 150%;
      max-width: 657px;

      @include media(576px) { font-size: 2rem; }
    }
  }
}
.image{
  display: none;
  @media (max-width: 996px) {
    display: inline;
    height: 400px;

  }
}