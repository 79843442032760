@import '../../scss/imports';

.acct {
  @include flexbox(
    $fd: column,
    $gap: 2rem
  );
  @include pushAuto;
  max-width: 147rem;
  padding: 0 0 4rem;

  // @include media(992px) {
  //   padding-right: 1.5rem;
  //   padding-left: 1.5rem;
  // }

  form {
    @include flexbox(
      $ai: flex-start,
      $jc: space-between,
      $fw: wrap,
      $gap: 2rem
    );
    width: 100%;

    > * {
      width: 100%;
    }
    
    h2 {
      @include media(992px) {
        width: 48%;
      }
      &:first-of-type {
        order: 0;
      }
      &:nth-of-type(2) {
        order: 2;

        @include media(992px) {
          order: 1;
        }
      }
    }
  }

  .block {
    padding: 0;

    &:first-of-type {
      order: 1;

      @include media(992px) {
        order: 2;
      }
    }
    &:nth-of-type(2){
      order: 3;
    }

    @include media(992px) {
      background-color: $gray248;
      min-height: 38rem;
      width: 48%;
    }

    .title {
      border-bottom-color: $gray217;
      margin-bottom: 1.5rem;
    }

    .items {
      @include border-rad(0.4rem);
      @include flexbox(
        $fd: column,
        $ai: flex-start,
        $gap: 0.5rem,
      );
      background-color: $gray248;
      padding: 1.5rem;

      @include media(768px) {
        @include flexbox(
          $fd: row,
          $ai: flex-start,
          $jc: space-between,
          $fw: wrap,
          $gap: 1rem,
        );
        padding: 2rem;
      }

      @include media(992px) {
        padding: 3rem;
      }

      > * {
        margin-bottom: 0;
        width: 100%;
      }

      .addressBlock {
        @include flexbox(
          $jc: space-between,
          $gap: 0.5rem,
          $fw: wrap
        );

        > * {
          @include media(576px) {
            width: 48%;
          }
        }

        > div {
          margin-bottom: 0;

          > div > div {
            @include flexbox(
              $fd: column,
              $ai: flex-start,
              $gap: 0.5rem,
            );

            label {
              @include font-size(12px);
              margin-bottom: 0!important;
            }
          }
        }
      }

      .half {
        @include media(768px) {
          width: 48%;
        }
      }
    }
  }

  .buttonBar {
    @include border-rad(0);
    @include flexbox($jc: flex-end);
    border-bottom: 0.1rem solid $gray217;
    order: 4;
    padding: {
      top: 0;
      right: 0;
      left: 0;
    }
    width: 100%;
  }

  .paymentBlock {
    @include flexbox(
      $fd: column,
      $ai: flex-start,
      $gap: 2rem
    );
    width: 100%;

    h2 {
      border: 0;
      margin: 0;
      padding: 0;
    }

    button {
      margin-left: auto;
    }
  }

  .paymentInfo {
    @include flexbox(
      $fd: column,
      $ai: flex-start,
      $gap: 0.2rem
    );
    @include font($size: 12px);
    width: 100%;

    @include media(576px) {
      @include flexbox(
        $fd: row,
      );
    }
    @include media(992px) {
      @include font-size(14px);
    }

    &.no {
      gap: 2rem;
      padding: 3rem;
    }

    .item {
      @include flexbox(
        $fd: column,
        $ai: flex-start,
        $gap: 0.5rem,
      );
      background-color: $gray248;
      font-weight: 600;
      width: 100%;

      > * {
        width: 100%;
      }

      &:first-of-type {
        @include border-rad(0.4rem 0.4rem 0 0);
      }
      &:last-of-type {
        @include border-rad(0 0 0.4rem 0.4rem);
      }

      .label {
        color: $gray116;
        // min-width: 16rem;
        padding: 1rem 1rem 0 1rem;
      }
      .entry {
        padding: 0 1rem 1rem;
      }
    }

    button {
      width: 100%;
    }
  }
}