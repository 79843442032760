@import '../scss/abstracts/variables';
@import '../scss/abstracts/mixins';

#login {
  @include fixed(0, 0, 0, 0, 1);
  @include flexbox($jc: center);
  background-color: $navy;

  @include media(1200px) {
    align-items: flex-start;

    padding: {
      top: 2%;
      bottom: 2%;
    }
  }

  #view {
    @include box-shadow(0, 4px, 20px, rgb(0 0 0 / 25%));
    background-color: $white;
    height: 100%;
    width: 100%;

    @include media(768px) {
      @include border-rad(8px);
      @include flexbox($fd: column, $jc: center);
      height: 640px;
      max-width: 96%;
      width: 735px;
    }

    @include media(1200px) {
      height: 100%;
      justify-content: flex-start;
      overflow: hidden;
      width: 115rem;
    }

    @include media(1400px) {
      max-width: none;
      width: 130rem;
    }

    #viewInner {
      @include relative($left: 0);
      @include square(auto);
      display: flex;
      padding: 30px 20px;
      transition: .35s ease-in-out;

      @include media(768px) {
        padding: 0;
      }

      @include media(1200px) {
        height: 100%;
        // padding: 50px 60px 0;
        transition: .85s ease-in-out;
      }

      .pane {
        .paneInner {
          @include flexbox($fd: column, $gap: 4px);

          h1,
          p,
          form {
            text-align: left;
            width: 100%;

            div {
              width: 100%;
            }
          }

          h1 {
            @include media(1200px) {
              margin-bottom: 4px;
            }
          }

          p {
            margin-bottom: 16px;
          }
        }

        .logo {
          svg {
            height: auto;
            width: 5rem;

            @include media(768px) {
              width: 9.5rem;
            }
          }
        }

        h1 {
          color: #000;

          font: {
            size: 16px;
            weight: 500;
          }

          line-height: calc(16px * 1.44);
          margin-bottom: 0 !important;

          @include media(768px) {
            font-size: 20px;
            line-height: calc(20px * 1.44);
          }

          @include media(1200px) {
            font-size: 24px;
            line-height: calc(24px * 1.44);
          }
        }

        p {
          font: {
            size: 12px;
            weight: 400;
          }

          line-height: calc(12px * 1.44);
          margin-bottom: 0 !important;

          @include media(768px) {
            font-size: 14px;
            line-height: calc(14px * 1.44);
          }

          @include media(1200px) {
            font-size: 16px;
            line-height: calc(16px * 1.44);
          }

          @include media(1400px) {
            font-size: 16px;
            line-height: calc(16px * 1.44);
          }
        }

        .form {
          @include flexbox($fd: column, $gap: 16px);
          width: 100%;

          @include media(768px) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
          }

          @include media(1200px) {
            max-width: 475px;
            width: 100%;
          }

          .formEl {

            .inner {
              @include flexbox(flex-start, column, flex-start, nowrap, 4px);

              label,
              input {
                width: 100%;
              }

              label,
              span {
                color: #383838;

                font: {
                  size: 12px;
                  weight: 400;
                }

                line-height: 12px * 1.44;

                @include media(768px) {
                  font: {
                    size: 14px;
                    weight: 400;
                  }

                  line-height: 14px * 1.44;
                }
              }

              span {
                color: #A00D06;
              }
            }
          }

          >div {
            padding: 0;

            &:first-of-type {
              margin-top: 0;
            }
          }

          label {
            @include font-size(12px);
            color: $blueGray;
            margin-bottom: 0rem;

            @include media(768px) {
              @include font-size(14px);
            }
          }

          input:not([type=submit]) {
            @include border-rad(4px);
            background-color: #fff;
            border: 1px solid #D9D9D9;
            color: #383838;

            font: {
              size: 12px;
              weight: 400;
            }

            height: auto;
            line-height: 12px * 1.44;
            padding: 8px;

            @include media(768px) {
              font-size: 16px;
              line-height: 16px * 1.44;
            }

            &:focus {
              border-color: $blue;
            }

            &::placeholder {
              color: #838383;
            }
          }

          .errorText {
            @include font($inter, 10px, 600);
            color: $red;
          }

          .reqAsterisk {
            color: $red;
          }

          .messageTxt {
            height: 16px;
          }

          .checkContainer {
            margin-top: .5rem;
            padding-top: .25rem;
            padding-left: 3rem;

            input,
            .checkmark {
              @include square(2.2rem);
            }

            input {
              @include absolute($top: 0, $left: 0);
              cursor: pointer;
              opacity: 0;

              &:checked~.checkmark {
                background-color: $blue;
                border-color: $blue;

                &::after {
                  display: block;
                }
              }
            }

            .checkmark {
              @include absolute($top: 0, $left: 0);
              @include border($width: 0.1rem, $color: $gray222 );
              @include border-rad(.2rem);
              background-color: $white;
              transition: .15s;

              &::after {
                @include absolute($top: 0, $left: 0.7rem);
                border: 0;
                border-right: 0.3rem solid $white;
                border-bottom: 0.3rem solid $white;
                @include css3-prefix(transform, rotate(45deg));
                @include pseudo(none, absolute);
                height: 17px;
                width: 7px;
              }
            }

            &:hover input~.checkmark {
              border-color: $blue;
            }
          }

          .actions {
            @include flexbox($fd: column, $ai: center, $jc: space-between, $gap: 12px);
            width: 100%;

            @include media(375px) {
              flex-direction: row;
            }

            button {
              min-width: 100px;
            }
          }

          &#loginForm {
            flex-direction: column !important;
          }
        }
      }
    }

  }

  &.create #view #viewInner {
    left: -100%;
  }

}

.resetPassPage {
  padding-top: 14rem;

  #header {
    @include fixed($top: 0, $right: 0, $left: 0, $z: 1000);
    background-color: $header;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .logoHolder {
      @include font($inter, 18px, 700);
      align-items: center;
      color: $white;
      display: inline-flex;
      gap: 2rem;
      justify-content: flex-start;
      line-height: 2.8rem;
      padding: 1rem 2rem 0;

      @include media(768px) {
        @include font-size(24px);
        padding-top: 0;
      }

    }
    .logoIcons {
      @include flexbox($gap: 20px);
    
      > span {
        @include border($width: 2px, $color: $navy);
        @include border-rad(8px);
        @include flexbox($jc: center);
        @include square(68px);
    
        > svg {
          @include css3-prefix(transform, scale(1.65));
        }
      }
    }
  }

  .contentBlock {
    width: 40rem;

    h2 {
      border-bottom: 0;
      line-height: 2.8rem;
      padding-bottom: 0;
    }
  }
}

.logoIcons {
  @include flexbox;
  gap: 1rem;

  .logo & {
    @include flexbox($jc: center, $gap: 10px);

    @include media(768px) {
      gap: 20px;
    }
  }

  span {
    @include border($width: 0.1rem, $color: $white );
    @include border-rad(.4rem);
    @include flexbox($jc: center);
    @include square(4rem);

    .logo & {
      @include square(36px);

      border: {
        color: $navy;
        style: solid;
        width: 1px;
      }

      padding: 4px;

      @include media(375px) {
        @include square(48px);
      }

      @include media(768px) {
        @include square(67px);
      }

      @include media(1200px) {
        @include square(80px);
        border-width: 2px;
        padding: 10px;
      }

      &:nth-of-type(2) {
        padding: 4px;
      }
    }
  }
}

#paneLogin,
#paneCreate {
  @include pushAuto();
  background-color: $white;
  flex: 0 0 24rem;

  @include media(768px) {
    flex: 0 0 100%;
  }

  @include media(1200px) {
    flex: 0 0 50%;
    height: 100%;
    margin-left: 0;
  }
}

#paneLogin {
  @include flexbox($fd: column, $ai: center, $jc: flex-start, $gap: 24px);
  flex: unset;
  width: 100%;

  @include media(1200px) {
    flex: 0 0 50%;
    gap: 48px;
    height: unset;
    padding-top: 80px;
  }

  > * {
    max-width: 335px;

    @include media(768px) {
      max-width: unset;
      width: 475px;
    }
  }

  .paneInner {
    gap: 32px !important;
    width: 100%;

    .header {
      @include flexbox(center, column, flex-start, nowrap, 16px);
      max-width: 475px;
      width: 100%;

      @include media(768px) {
        gap: 30px;
      }

      @include media(1200px) {
        gap: 48px;
      }

      .copy {
        @include flexbox(flex-start, column, flex-start, nowrap, 4px);
        padding-top: 0;
        width: 100%;

        @include media(1200px) {
          gap: 0;
        }
      }
    }
  }
}

.copy {
  font: {
    size: 10px;
    weight: 400;
  }

  line-height: 10px * 1.44;
  max-width: 355px;
  width: 100%;

  @include media(768px) {
    font-size: 12px;
    max-width: unset;
  }

  @include media(1200px) {
    font-size: 14px;
  }
}

#paneCreate {
  display: none;
}

#paneCow {
  @include relative;
  @include square(100%);
  background-blend-mode: multiply;
  background-position: 19% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  min-width: 100% !important;
  overflow: hidden;
  padding: 0 !important;

  .create & {
    background-position: 82% 100%;
  }

  @include media(1200px) {
    @include flexbox($ai: flex-end);
    max-width: 50%;
  }

  img {
    display: none;
    height: auto;
    mix-blend-mode: multiply;
    width: 100%;

    @include media(768px) {
      display: block;
    }
  }
}