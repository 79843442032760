// file: client/src/styles.scss

@import './scss/imports';

.textWhite       { color: $white; }
.textGray73      { color: $gray73; }
.textGray79      { color: $gray79; }
.textGray56      { color: $gray56; }
.textGray136     { color: $gray136; }
.textGray182     { color: $gray182; }
.textGray196     { color: $gray196; }
.textGray222     { color: $gray222; }
.textGray246     { color: $gray246; }
.textGray248     { color: $gray248; }
.textGray250     { color: $gray250; }
.textBlack       { color: $black; }
.textBg          { color: $bg; }
.textSubmenuBg   { color: $submenuBg; }
.textSubmenuBgH  { color: $submenuBgH; }
.textMenuBg      { color: $menuBg; }
.textMenuBgH     { color: $menuBgH; }
.textMenuCopy    { color: $menuCopy; }
.textNavy        { color: $navy; }
.textBlueBlack   { color: $blueHomeBlack; }
.textBlue        { color: $blue; }
.textBlueGray    { color: $blueGray; }
.textRed         { color: $red; }
.textGreen       { color: $green; }
.textNegTrend    { color: $negTrend; }
.textPosTrend    { color: $posTrend; }

.bgWhite         { background-color: $white; }
.bgGray73        { background-color: $gray73; }
.bgGray79        { background-color: $gray79; }
.bgGray56        { background-color: $gray56; }
.bgGray136       { background-color: $gray136; }
.bgGray182       { background-color: $gray182; }
.bgGray196       { background-color: $gray196; }
.bgGray222       { background-color: $gray222; }
.bgGray246       { background-color: $gray246; }
.bgGray248       { background-color: $gray248; }
.bgGray250       { background-color: $gray250; }
.bgBlack         { background-color: $black; }
.bgBg            { background-color: $bg; }
.bgSubmenuBg     { background-color: $submenuBg; }
.bgSubmenuBgH    { background-color: $submenuBgH; }
.bgMenuBg        { background-color: $menuBg; }
.bgMenuBgH       { background-color: $menuBgH; }
.bgMenuCopy      { background-color: $menuCopy; }
.bgNavy          { background-color: $navy; }
.bgBlueBlack     { background-color: $blueHomeBlack; }
.bgBlue          { background-color: $blue; }
.bgBlueGray      { background-color: $blueGray; }
.bgRed           { background-color: $red; }
.bgGreen         { background-color: $green; }
.bgNegTrend      { background-color: $negTrend; }
.bgPosTrend      { background-color: $posTrend; }

html,
body,
#root,
.App,
.non-footer,
#PageContainer { height: 100%; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 62.5%;

  body {
    @include font( $inter, 14px, 400 );
    @include relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    color: $gray73;
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-kerning: normal;
    height: 100%;
    line-height: 1.7rem;
    
    #PageContainer { background-color: $gray248;
      
      hr { margin-bottom: 2rem; }
    }
  }
}


// Main Content
#mainContent {
  @include flexbox( $ai: flex-start, $fd: column );
  @include relative;
  gap: 2rem;
  padding: 9rem 1.5rem 1.5rem;
  min-height: 100vh;

  @include media(992px) { padding: 2rem 2rem 2rem 19.5rem; }
  @include media(1100px) { padding: 4rem 4rem 4rem 27rem; }

  &::after {
    @include pseudo();
    @include fixed( 0, 0, 0, 0, -1 );
    background-color: $gray248;
  }

  > * { width: 100%; }

  .subTitle { margin-bottom: 2rem; }

  &.admin {
    margin: auto;
    max-width: 147rem;
    padding: 9rem 1.5rem 4rem;
  }

  &.producer {
    gap: 3rem;
    margin: auto;
    padding: 8rem 2rem 4rem;
    
    @include media(992px) { padding: 8rem 4rem 4rem; }

    &::after { background-color: $white; }
  }

  &.noPadding {
    margin: 0px;
    padding: 60px 0px 0px 0px;
    height: calc(100vh - 60px);
  }

  &.vet::after {
    background-color: #fff;
  }

  > #mainContent {
    padding: 0;

    &::after { display: none; }
  }
  
}

.totalCattle {
  @include font-size( 18px );
  line-height: 100%;
}

section.apps .contain > div { margin-bottom: 1.5rem; }


// Filters
.filters {
  @include flexbox( $ai: flex-start, $fd: column, $jc: space-between, $gap: 1rem );
  // gap: 1rem;
  // margin-bottom: 2rem;

  @include media(576px) {
    align-items: flex-end;
    flex-direction: row;
  }
  
  .filter {
    @include flexbox( $ai: flex-start, $fd: column, $gap: 0.5rem );
    width: 100%;

    @include media(576px) {
        min-width: 15rem;
        width: auto;
    }
    @include media(992px) {
        min-width: 0;
        width: 15rem;
    }

    &:nth-of-type(2) { margin-right: auto; }
    
    label,
    select {
      @include font-size( 12px );
      color: $gray56;
      line-height: 100%;
    }
    label {
      font-weight: 700;
      line-height: 1.8rem;
    }
    select {
      // font-weight: 400;
      height: 3rem;
      padding: 0 1rem;
      width: 100%;
    }
  }
}


// Show For / Display
.showTabs {
  @include flexbox( $ai: flex-start, $fd: column, $gap: 0.5rem );
  flex: unset;
  width: 100%;

  label {
    @include font( $inter, 12px, 700 );
  }
  
  button {
    @include relative;
    @include border-rad( 0 );
    margin-left: 0!important;
    min-width: 0;
    width: 100px;
    
    &::after {
      @include absolute( $right: 0, $bottom: 0, $z: 1 );
      @include pseudo();
      background-color: $gray56;
      height: 0.1rem;

    //   @include media(576px) {
    @include media(400px) {
        height: auto;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0.1rem;
      }
    }

    &:last-of-type::after { display: none; }
  }
}
.display button { width: 8rem!important; }

.actions button {
  @include font( $inter, 14px, 700 );
  height: auto;
  padding: 1rem 2rem;
}

.groups .group {
  @include font( $inter, 10px, 500 );
  line-height: 1.4rem;
}
.dot {
  @include border-rad( 50% );
  @include square( 1rem );
}

.rowActions .btn {
  @include font( $inter, 14px, 700 );
  height: 3.4rem;
  line-height: 100%;
  padding: 0 2rem;
}


/* Viewer */
#viewer {
  @include fixed( 0, 0, 0, 0, 1001 );
  @include flexbox( $jc: center );
  background-color: rgb(47 49 51 / 60%);
  
  .contain {
    @include square( 96% );
    background-color: $white;
    box-shadow: 0 4px 10px 4px rgb(0 0 0 /15%);
    max-height: 78.8rem;
    max-width: 140rem;
    overflow: hidden;
    padding: 0;
    
    .head {
      @include flexbox;
      background-color: $blueHomeBlack;
      color: $white;
      gap: 1rem;
      height: 50px;
      
      .title {
        @include flexbox;
        @include font( $inter, 20px, 700 );
        height: 5rem;
        padding-left: 2rem;
      }
      
      .switches {
        padding-left: 4rem;
        
        .formSwitch { margin: 0 6rem 0 0; }
        
        .formLabel {
          @include font( $inter, 14px, 600 );
          margin: 0 1rem 0 0;
        }
        .formCheckInput {
          height: 2rem;
          
          &:checked {
            background-color: $blue;
            border-color: $blue;
          }
        }
      }
      
      button.closeBtn {
        border: 0;
        @include bd-left( $width: 0.1rem, $color: $menuBg );
        @include font-size( 20px );
        background-color: transparent;
        color: $white;
        height: 5rem;
        margin-left: auto;
        opacity: .75;
        transition: .15s;
        width: 5rem;
        
        &:hover { opacity: 1; }
      }
    }
  }
}

.bgBlock {
  @include border-rad( 0.8rem );
  padding: 2rem;
}



.infoBlock {
  @include border-rad( 0.4rem );
  padding: 2rem;
}


.selectBlock {
  @include flexbox( $ai: flex-start, $fd: column );
  gap: 1rem;

  @include media(576px) { @include flexbox( $ai: center, $fd: row ); }

  label {
    @include font( $size: 12px, $weight: 700 );
    margin-bottom: 0.5rem;

    @include media(576px) { margin-bottom: 0; }
  }

  select {
    height: 2.8rem;
    min-width: 23.3rem;
    padding-left: 1rem;
  }
}

.ddFilter,
.showFor {
  @include media(576px) { flex: unset; }
}
.ddFilter {
  @include media(576px) { width: 15rem; }
}
.showFor {
  @include media(576px) { width: 32%; }
  @include media(1300px) { width: 30rem; }

  .btn {
    @include media(1300px) {
      @include font-size( 12px );
      width: 10rem;
    }
  }
}
.actions .btn {
  @include media(576px) { width: 32%; }
}


.selectLocation {
  @include flexbox;
  gap: 1.3rem;
  width: 100%;

  @include media(576px) { margin-left: auto; }

  label {
    @include font( $inter, 12px, 700 );
    color: $gray56;
  }

  select {
    @include font( $inter, 12px, 400 );
    height: 3rem;
    padding-left: 1rem;
    width: 15rem;
  }
}
