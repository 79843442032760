// colors
$black:         #000000;
$gray56:        #383838;
$gray73:        #494949;
$gray79:        #4F4F4F;
$gray136:       #888888;
$gray149:       #959595;
$gray182:       #B6B6B6;
$gray196:       #c4c4c4;
$gray207:       #CFCFCF;
$gray222:       #DEDEDE;
$gray243:       #F3F3F3;
$gray246:       #F6F6F6;
$gray248:       #F8F8F8;
$gray250:       #FAFAFA;
$white:         #FFFFFF;

$bg:            #EFECEC;

$header:        #1D1B19;
$navActive:     #252320;
$blueHome:      #027BA7;
$navy:          #29313B;
$blueHomeBlack: #1C2229;
$blue:          #3099FD;
$blueGray:      #9BB1CC;

$submenuBg:     #343F4B;
$submenuBgH:    #3E4B5A;
$menuBg:        #3C4956;
$menuBgH:       #465665;
$menuCopy:      #B1C0D0;

$greenHome:     #64B08B;
$yellowGreen:   #C4E36E;
$green:         #83D75C;
$posTrend:      #73D451;

$red:           #F16060;
$negTrend:      #E35B5B;