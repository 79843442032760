// file: client/src/scss/base/_forms.scss

/** Forms ----- */
form,
.form {
  .item {
    @include font-size( 14px );
    display: flex;
    flex-direction: column;
    line-height: 150%;
    margin-bottom: 2.5rem;

    &:last-of-type { margin-bottom: 0; }
    
    label {
      font-weight: 700;
      margin-bottom: 1.2rem;
      
      span { @include font( $inter, 12px, 400 ); }

      &.check {
        @include css3-prefix( user-select, none );
        @include font( $inter, 14px, 400 );
        cursor: pointer;
        margin-bottom: 0!important;
        padding-left: 1.8rem;
      }
    }
    
    &.horiz {
      margin-bottom: 2rem;
      
      label { margin-bottom: .5rem!important; }
    }

    &.icon {
      
      .icon {
        @include border-rad( 0.4rem 0 0 0.4rem );
        @include square( 4rem );
        background-color: $gray182;
        bottom: 0;
        left: 0;
        
        @include media(768px) { left: 0; }
      }

      input:not([type='submit']) { padding-left: 6rem; }
    }
  }

  .formBlockWhite {
    @include border-rad( 0.8rem );
    padding: 2rem;
  }

  label {
    &.check {
      input {
        @include square( 0 );
        cursor: pointer;
        opacity: 0;
        
        &:checked ~ .checkmark {
          background-color: $white;
          border-color: $blue;
          
          &::after { display: block; }
        }
      }
      
      .checkmark {
        @include border( $width: 0.1rem, $color: $gray182 );
        @include border-rad( 0.2rem );
        @include square( 1.2rem );
        background-color: $white;
        top: 0.15rem;
        left: 0;
        
        &::after {
          @include absolute( $top: 0.1rem, $left: 0.1rem, $z: 1 );
          @include border-rad( 0.1rem );
          @include square( 0.8rem );
          content: '';
          display: none;
          background-color: $blue;
        }
      }

      &:hover input ~ .checkmark { border-color: $blue; }
    }
  }

  .switchBlock {
    @include border-rad( 0.4rem );

    .custom-switch {
      height: 1.6rem;
      margin-bottom: 0!important;
      width: 3rem;
      
      span.text {
        @include font( $inter, 14px, 700 );
        line-height: 1.6rem;
        padding-left: 4rem;
      }

      input {
        @include square( 0 );
        opacity: 0;
        top: 0;
        
        &:checked + .slider {
          background-color: $blue;
          border-color: $blue;

          &::before {
            @include css3-prefix( transform, translateX(1.4rem) );
            background-color: $white;
          }
        }
        &:focus + .slider { @include box-shadow( $blur: 1, $color: $blue ); }
      }

      .slider {
        @include border( $width: 0.1rem, $color: $gray182 );
        @include border-rad( 3.4rem );
        @include css3-prefix( transition, .4s );
        background-color: $white;
        cursor: pointer;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        &::before {
          @include absolute( $bottom: 0.1rem, $left: 0.1rem );
          @include border-rad( 50% );
          @include css3-prefix( transition, .4s );
          @include square( 1.2rem );
          background-color: $gray182;
          content: '';
        }

        &:hover { border-color: $blue;
          
          &::before { background-color: $blue; }
        }
      }
    }
  }
}

input:not([type='submit']),
select,
textarea {
  @include border( $width: 0.1rem, $color: $gray182 );
  @include border-rad( 0.4rem );
  outline: none;
  height: 4rem;
  padding: 0 2rem;
  transition: .15s ease-in-out;
  
 &:focus { border-color: $blue; }
}

input[type='checkbox'] {
  @include square( 2rem );
}

textarea {
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fileUpload {
  .upload {
    @include border( $width: 0.1rem, $color: $gray182 );
    @include border-rad( 0.8rem );
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 18.2rem;

    @include media(576px) { padding-top: 34.8rem; }
    @include media(768px) { padding-top: 27rem; }
    @include media(992px) { padding-top: 28.2rem; }
    
    > div,
    button {
      @include border-rad( 0.8rem 0 0.8rem 0 );
      @include square( 5rem );
      border: 0;
      right: -0.1rem;
      bottom: -0.1rem;
    }
  }
}
