// footer styles

@import '../../../styles/imports';

#footer {
  background-color: $header;
  @include position( fixed, auto, 0, 0, 0, 1000 );

  .logo {
    padding: .7rem 0;
  
    svg {
      height: 1.6rem;
      width: 1.9rem;

      path { fill: $gray149; }
    }
  }
}