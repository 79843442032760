@import '../../scss/imports';

$h: 6rem;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dropdownContainer {
  @include absolute(
    $right: 8px,
    $bottom: 8px,
    $left: 8px,
  );
  border: 0.1rem solid rgb(255 255 255 / 15%);
  border-radius: 8px;
  overflow: hidden;

  cursor: pointer;
  line-height: $h;
  padding: 0;
  user-select: none;

  @include media(768px) {
    border: unset;
    height: $h;
    
    display: flex;
    align-items: center;
    
    inset: auto;
    overflow: visible
  }

  > div:first-of-type {
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      svg {
        animation: spin 2s ease-in-out infinite;

        path {
          fill: $white;
        }
      }
    }
  }


  .dropdown {
    @include border-rad(0 0 0 0.3rem);
    @include css3-prefix(transition,
      .15s ease-in-out);
    @include flexbox($ai: flex-start,
      $fd: column,
    );
    @include static;
    background-color: #000;
    color: white;
    width: 100%;

    @include media(768px) {
      @include fixed(
        $top: 60px,
        $right: 0,
        $z: -999,
      );
      @include css3-prefix(transition,
        .15s ease-out);
      @include opacity(0);
      visibility: hidden;
      width: 20rem;
    }

    &.open {
      @include opacity;
      visibility: visible;
      z-index: 999;
    }

    &::before {
      @include absolute(0, 0, 0, 0, -1);
      background-color: rgb(0 0 0 / 15%);
      content: '';
    }

    > {
      * {
        width: 100%;
      }

      div {
        @include flexbox($ai: flex-start,
          $fd: column,
          $gap: 0.5rem);
        order: 1;

        label {
          color: $menuCopy;
        }

        select {
          width: 100%;
        }

        &:last-of-type {
          border-bottom: 1px solid rgb(255 255 255 / 15%);
          padding-top: 0;
        }
      }

      button {
        order: 2;
      }
    }

    h5 {
      @include font($weight: 600);
      border-bottom: 1px solid rgb(255 255 255 / 15%);
      color: $white;
      cursor: default;
      margin-bottom: 0;
      padding: 1rem;
      order: 0;

      span.email {
        @include font($size: 10px, $weight: 500);
        color: $blueGray;
        display: block;
      }
    }

    a.link {
      @include css3-prefix(transition, 0.15s);
      border-bottom: 1px solid rgb(255 255 255 / 15%);
      color: rgb(255 255 255 / 90%) !important;
      font-size: 1.2rem !important;
      font-weight: 600 !important;
      height: auto !important;
      line-height: 100% !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      padding: 1rem !important;
      text-align: left !important;
      order: 2;

      &::after {
        display: none;
      }

      &:hover {
        background-color: $blueGray;
        color: $white !important;
      }
    }
  }

  svg {
    display: none;

    @include media(768px) {
      @include square(1.8rem);
      display: block;
    }

    @include media(1200px) {
      @include square(24px);
    }
  }

  .locSelect {
    padding: 1rem;

    h5 {
      @include font($size: 10px, $weight: 500);
      border: 0;
      color: $blueGray;
      line-height: 100%;
      padding: 0;
    }

  }

  button {
    @include border-rad(0);
  }
}