// home styles

@import '../styles/imports';
#PageContainer{
  background-color: #000000 !important;
  padding: 0 20px;
}

.textBlack {
  color: $black;
}

.textHeader {
  color: $header;
}

.textGray136 {
  color: $gray136;
}

.textGray149 {
  color: $gray149;
}

.textGray207 {
  color: $gray207;
}

.textGray243 {
  color: $gray243;
}

.textWhite {
  color: $white;
}

.textGreen {
  color: $greenHome;
}

.textBlue {
  color: $blueHome;
}

.bgBlack {
  background-color: $black;
}

.bgHeader {
  background-color: $header;
}

.bgGray136 {
  background-color: $gray136;
}

.bgGray149 {
  background-color: $gray149;
}

.bgGray207 {
  background-color: $gray207;
}

.bgGray243 {
  background-color: $gray243;
}

.bgWhite {
  background-color: $white;
}

.bgGreen {
  background-color: $greenHome;
}

.bgBlue {
  background-color: $blueHome;
}

.main {
  padding-top: 0rem;
  padding-bottom: 10rem;
  margin-top: 60px;

  section.container {
    gap: 3rem;
    margin: auto;
    max-width: 100%;
  }
}

// Buttons
.btn {
  @include font($inter, 12px, 700);
  height: 4rem;
  padding: 0;
  width: 14.5rem;

  form & {
    background-color: rgb(255 255 255 / 10%);
    border: 2px solid $white;
    color: $white;
    margin-right: 2rem;

    &:hover {
      background-color: $white;
      color: $gray136;
    }
  }

  &.btnSpecial {
    background-color: $blueHome;
    border: 0;
    color: $white;
    overflow: hidden;

    &::after {
      background: linear-gradient(270deg, #C4E36E 0%, rgb(2 123 167 /0%) 100%);
      content: '';
      @include position(absolute, -1px, 0, 0, 0, 0);
      @include css3-prefix(transition, .25s ease-in-out);
      width: 15rem;
    }

    span {
      z-index: 1;
    }

    &:hover {
      &::after {
        @include css3-prefix(transform, translateX(15rem));
      }
    }
  }
}

// Categories
#categories {
  gap: 2rem;

  .title {
    background-color: $black;
    border-radius: 8px;
    color: $white;
    padding: 2rem;

    h3 {
      @include font($inter, 24px, 600);
      line-height: 2.8rem;
    }
  }

  .holder {
    gap: 2rem;

    .card {
      @include media(768px) {
        height: 43rem;
        width: 31.5%;
      }

      @include media(992px) {
        height: 40rem;
      }

      @include media(1200px) {
        height: 39rem;
      }
    }
  }

  .card {
    background-color: $white;
    border-radius: 8px;
    min-height: 38rem;
    padding: 4rem 2rem;

    h3 {
      @include font($inter, 24px, 600);
      line-height: 2.8rem;
    }

    p {
      @include font-size(18px);
      line-height: 150%;
    }
  }
}
.homeContent {
  padding: 2rem 0rem;

  @include media(768px) {
    padding: 3rem 0rem;
  }

  h2 {
    @include font-size(30px);
    border: 0;
    line-height: 125%;
    padding-bottom: 0;
  }

  p {
    @include font($inter, 20px, 400);
    line-height: 150%;
    margin-bottom: 1.5rem;

    &.sm {
      @include font($inter, 18px, 500);
      margin-bottom: 2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  form {
    border-radius: 0;
    gap: 2rem;
    padding-top: 5rem;

    &#formInTheKnow {
      .item {
        margin-bottom: 2rem;

        @include media(576px) {
          margin-bottom: .5rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      button {
        margin: 0 auto;

        @include media(576px) {
          margin-right: 0;
        }
      }
    }

    label {
      @include font($inter, 12px, 600);
      line-height: 1.5rem;
      margin-bottom: .5rem;
    }

    input {
      background-color: $white;
      border: 0;
      border-radius: 4px;
      box-shadow: none;
      height: 38px;
      line-height: 38px;
    }
  }

  // &.prod {
  //   p.contentP {
  //     &::before {
  //       @include pseudo();
  //       @include position(absolute, 0, 2rem, auto, 2rem, 1);
  //       background-color: #CFCFCF;
  //       height: .1rem;
  //     }
  //   }
  // }
}

.iconContainer {
  padding-right: 2rem;
  margin-top: -4.5rem;
}

.textContainer {
  max-width: 80%;
  @include media(768px) { max-width: 100%; }
  @include media(992px) { max-width: 85%; }

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    
  }
}

.greenBorder {
  border: 3px solid $blue;
  border-radius: 8px;
  @include position(absolute, 0, 0, 0, 0, 1);
}

.monitors {
  background-color: #000000;
  padding: 8rem 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .contentContainer {
    display: flex;
    height: 594px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .textContainer {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    flex: 1;
    /* Ensure the text container takes up available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center content vertically */
  }

  .iconBoxesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    /* Ensure the icon boxes container takes up available space */
  }

  h3,
  p {
    color: #ffffff;
  }

  h3 {
    font-size: 36px;
    font-weight: 600;
  }

  h4 {
    color: #C9E4F5;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    padding: 12px 0 28px
  }


  .iconBox {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 20px;


    h3 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }

    svg {
      height: 100px;
    }
  }

  @media (max-width: 768px) {
    .iconBox {
      flex: 1 1 100%;
    }
  }
}

.featuresContainer {
  background-color: #000000;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .contentContainer {
    display: flex;
    height: 594px;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    height: 100%;
  }

  .textContainer {
    width: 100%;
    max-width: 100%;
    
    flex: 1;
    /* Ensure the text container takes up available space */
    display: flex;
    flex-direction: column;
    justify-content: start;
    /* Center content vertically */
    // padding: 20px;
    @media screen and (max-width: 768px) {
      h3{
        font-size: 24px !important;
        line-height: 36px !important;
        padding-bottom: unset !important;
      }
      
    }
    
  }
 
  .iconbox {
      display: flex;
      flex-direction: column;
      align-items: flex-start; 
      padding-left: 20px; 
  
      @media (max-width: 1200px) {
        padding-left: 15px; 
      }
  
      @media (max-width: 992px) {
        padding-left: 10px; 
      }
  
      @media (max-width: 768px) {
        padding-left: 5px; 
      }
  
      @media (max-width: 576px) {
        padding-left: 0; 
      }
    }

  .iconBoxesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    /* Ensure the icon boxes container takes up available space */
  }

  h3,
  p {
    color: #ffffff;
  }

  h3 {
    font-size: 36px;
    font-weight: 600;
  }

  h4 {
    color: #C9E4F5;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
    margin: unset !important;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    padding: 12px 0 28px
  }


  .iconBox {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    align-items: start !important;
    margin: 10px;
    
    .textContainer{
      padding: 0;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }

    svg {
      height: 100px;
    }
  }

  @media (max-width: 768px) {
    .iconBox {
      flex: 1 1 100%;
    }
  }
}

p.small, div.small {
  font-size: 16px !important;
}
.featureTitle{
  font-size: 30px;
  line-height: 44px;
  color:#ffffff;
}

div.small li {
  padding: 10px  0;
}

.linksContainer{
  a{
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-right: 28px ;
  }
}
.footerHr {
  margin-top: 70px;
  border: 0;
  height: 1px;
  width: 100%;
  background-color: #E4E7EC; 
}
.footerText{
  font-size: 16px !important;
  color: #475467;

}
.copyRight{
  padding: 20px 0;
  font-size: 16px;
  font-weight: 400;
  color: #667085;
}
.logoHolder {
  display: flex;
  align-items: center;
  text-decoration: none;

  .logoIcons {
    display: flex;
    gap: 10px; // Adjust the gap between icons as needed
    padding-left: 2px;

    span {
      height: 32px;
      width: 32px;
      background-clip: padding-box;
      border-radius: 0.4rem;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      border: 1px solid #000000;
      padding: 3px;
    }

  }

  h4 {
    margin-left: 10px !important; 
    color: #000000; 
    font-size: 20px !important;
    font-weight: 700;
  }
}
#intallIntegrate {
  > div {
    max-width: 100% !important;
  }
  flex-direction: column !important;
  h2 {
    font-size: 36px;
    line-height: 44px;
    padding-bottom: 24px;
    text-align: center;
    border: none;
  }
  
}
.iconboxRight {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  padding-left: 4rem;
  @media (max-width: 992px) {
    padding-left: 10px; // Adjust padding for even smaller screens
  flex-basis: 100% !important;
  order: 1;
  }
}

.iconboxLeft {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;

  padding-left: 220px; // Default padding

  @media (max-width: 1200px) {
    padding-left: 15px; // Adjust padding for smaller screens
  }

  @media (max-width: 992px) {
    padding-left: 10px; // Adjust padding for even smaller screens

  flex-basis: 100% !important;
  }

  @media (max-width: 768px) {
    padding-left: 5px; // Adjust padding for mobile screens
  }

  @media (max-width: 576px) {
    padding-left: 0; // Remove padding for very small screens
  }
}
.featureTitle{

  color:#ffffff !important;
}
.featureBgImg{
  @media screen and (max-width: 992px) {
    
    background-position: bottom center !important;
    background-size: 80vw !important;
    width: 100%;
    height: 360px !important;
    margin-top: 20px;
    &.integrate{
      background-image: url(../images/how-integrate-m.png) !important;
      height: 240px !important;
    }
    &.monitoring{
      background-image: url(../images/how-real-time-m.png) !important;
    }
    &.alerts{
      background-image: url(../images/how-instant-alerts-m.png) !important;
    }
    &.data{
      background-image: url(../images/how-data-driven-m.png) !important;
    }
    
  }

}