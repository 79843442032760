// file: client/src/scss/base/_modals.scss

.modal {
  &.show {
    display: flex !important;
  }

  .backdrop {
    @include fixed(0, 0, 0, 0, 0);
    background-color: rgb(0 0 0 / 35%);
  }

  &-dialog {
    height: unset;
    min-height: unset;
    width: 100%;

    &.modal-sm {
      max-width: 37.2rem;
    }
  }

  &-content,
  &-header {
    border: 0;
  }

  &-content {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
    max-height: 80% !important;
    overflow: hidden;
  }

  &-header {
    @include relative();
    border-radius: 0;
    background-color: $navy;
    color: $white;
    padding: 0 2rem;

    .modal-title {
      @include font($inter, 16px, 700);
      color: $white;
      line-height: 4rem;
      text-transform: capitalize;
    }

    .btn-close {
      background-color: $red;
      opacity: .85;
      transition: .3s;

      &:hover {
        opacity: 1;
      }
    }

    .close {
      @include absolute($top: 0, $right: 0, $bottom: 0);
      @include border-rad(0 0.4rem 0 0);
      background-color: transparent;
      border: 0;
      color: $white;
      height: 40px !important;
      padding: 0;
      transition: .15s;
      width: 52px !important;

      span {
        @include font-size(22px);
      }

      &:hover {
        background-color: $gray246;

        svg path,
        span {
          color: $navy;
          fill: $navy;
        }
      }
    }
  }

  &-body {
    padding: 2rem;
    padding-bottom: 35px;
  }

  &-footer {
    // @include absolute($right: 0, $bottom: 0, $left: 0);
    // @include border-rad(0 0 0.8rem 0.8rem);
    @include flexbox($jc: space-between);
    background-color: $gray246;
    border: 0;
    padding: 2rem;

    button {
      height: auto !important;
      line-height: 3.4rem !important;
      margin: 0;
      padding: 0 2rem !important;
      width: auto !important;

      @include media(992px) {
        @include font($inter, 14px, 700);
        height: 3.4rem !important;
        margin: 0;
        padding: 0 2rem !important;
        width: auto !important;
      }
    }
  }
}

.nav-tabs {

  .nav {

    &-item {
      width: 50%;

      .nav-link {
        @include font($size: 10px, $weight: 700);
        @include relative;
        border: 0;
        color: $gray56;
        height: 3rem;
        line-height: 3rem;
        padding: 0;

        &::after {
          @include absolute($right: 0, $bottom: 0, $left: 0);
          background-color: transparent;
          content: '';
          height: 0.3rem;
        }

        &.active {
          color: $blue;

          &::after {
            background-color: $blue;
          }
        }
      }
    }
  }
}