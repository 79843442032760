// file: client/src/Home/Topbar/styles.module.scss

@import '../../scss/imports';

$h: 6rem;

.loginBtn {
	width: auto!important;

	@include media(768px) {
	@include relative;
	height: $h;
		padding: {
			right: 2rem;
			left: 2rem;
		}
	}
	@include media(992px) {
		padding: {
			right: 3.3rem;
			left: 3.3rem;
		}
	}
}
