@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.chatItems {
  background-color: #F6F6F6;
  height: calc(100vh - 6rem);
  max-width: 200px;
  
  @include media(992px) {
    max-width: 260px;
  }
  @include media(1100px) {
    max-width: 35.5rem;
  }

  .search {
    @include relative;
    border-bottom: 0.1rem solid #D9D9D9;
    height: 7.1rem;
    padding: 2rem 1rem;

    @include media(992px) {
      padding: 2rem;
    }
    @include media(1200px) {
      width: 35.5rem;
    }

    > svg {
      @include absolute($top: 50%, $left: 3rem);
      @include css3-prefix(transform, translateY(-50%));
    }

    > input {
      background-color: #fff;
      border: 0.1rem solid #d9d9d9;
      border-radius: 1.5rem;
      font-size: 1.2rem;
      height: 3rem;
      outline: none;
      padding-left: 3rem;
      width: 100%;
    }
  }

  .messages {
    height: calc(100vh - 13.1rem);
    overflow-y: scroll;

    .message {
      @include flexbox($ai: center, $fd: row, $gap: 1rem);
      @include relative;
      border-bottom: 0.1rem solid #D9D9D9;
      padding: 10px 20px 10px 10px;

      @include media(992px) {
        padding: 2rem;
      }

      &::after {
        @include absolute($top: 0, $right: 0, $bottom: 0);
        background-color: transparent;
        content: '';
        width: 0.4rem;
      }
      &.active {
        background-color: #fff;

        &::after {
          background-color: #3E9CDB;
        }
      }

      &.closed {
        background-color: #d9d9d9;
        border-bottom-color: #C4C4C4;
      }

      .dot {
        border: 0.1rem solid transparent;
        border-radius: 50%;
        height: 0.8rem;
        width: 0.8rem;

        &.unread {
          background-color: $brightBlue;
          border-color: $brightBlue;
        }
      }
      .image {
        background: {
          position: center;
          repeat: no-repeat;
          size: cover;
        };
        border-radius: 0.4rem;
        display: none;
        height: 6rem;
        width: 6rem;

        @include media(992px) {
          display: block;
        }
      }
      .info {
        @include flexbox($ai: flex-start, $fd: column, $gap: 1rem);
        flex: 1;

        .idPenDate {
          @include flexbox($jc: space-between, $gap: 1.5rem);
          @include font($family: 'Inter', $size: 11px);
          color: #383838;
          width: 100%;

          .date {
            color: #737373;
            margin-left: auto;
          }
        }

        p {
          @include font($family: 'Inter', $size: 11px);
          color: #383838;
          line-height: 130%;
        }
      }
    }
  }
}