// file: client/src/Admin/styles.module.scss

@import '../scss/imports';

$cw1: 9.55882%;
$cw2: 10.14706%;
$cw3: 11.76471%;
$cw4: 15.73529%;
$cw5: 11.69118%;
$cw6: 11.83823%;
$cw7: 9.48529%;

#customers,
#metrics,
#overview,
#locations { background-color: $white; }

#overview {
  @include flexbox( $ai: flex-start, $fd: column );
  gap: 2rem;

  .contentBlock {
    @include flexbox( $ai: flex-start, $fd: column, $jc: space-between );
    width: 100%;

    @include media(576px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .item {
      margin-bottom: 3rem;
      width: 100%;

      @include media(576px) { width: 50%; }
      @include media(768px) { width: 20%; }
      @include media(992px) { width: 17%; }

      &.actions {
        @include flexbox( $fd: column, $jc: flex-end );
        margin-bottom: 0;
        width: 100%;

        @include media(576px) {
          @include pushAuto;
          width: 23.3rem;
        }
        @include media(992px) {
          margin-right: 0;
          width: 23.3rem;
        }

        button {
          min-width: 0;
          width: 100%;
          margin-bottom: 1rem;

          @include media(576px) {
            margin-right: auto;
            margin-left: auto;
          }
          @include media(992px) { margin-right: 0; }

          &:last-of-type { margin-bottom: 0rem; }
        }
      }
    }
  }

  button {
    @include font( $inter, 12px, 400 );
    height: 2.8rem;
    min-width: 23.3rem;

    &:nth-of-type(2) { font-weight: 700; }
  }
}

#titleLocation {
  @include bd-bottom( $width: 0.1rem, $color: $gray56 );
  @include flexbox( $ai: flex-start, $fd: column, $jc: space-between );
  gap: 1rem;
  padding-bottom: 2rem;
  width: 100%;

  @include media(576px) { @include flexbox( $ai: center, $fd: row, $jc: space-between ); }

  h2 {
    @include font( $size: 24px, $weight: 700 );
    border-bottom: none!important;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    width: auto;
  }

  > div {
    gap: 0.5rem;
    width: 100%;

    @include media(576px) {
      width: auto;
      // gap: 1rem;
    }

    label { margin-bottom: 0; }

    select {
      min-width: 0;
      width: 100%;
      
      @include media(576px) { width: 17.5rem; }
      @include media(768px) { width: 23.3rem; }
    }
  }
}

#customersTable { margin-bottom: 0;

  tr {
    th,
    td {
      @include media(992px) {
        &:first-of-type {
          padding-left: 2rem;
          width: $cw1;
        }
        &:nth-of-type(2) { width: $cw2; }
        &:nth-of-type(3) { width: $cw3; }
        &:nth-of-type(4) { width: $cw4; }
        &:nth-of-type(5) { width: $cw5; }
        &:nth-of-type(6) { width: $cw6; }
        &:nth-of-type(7),
        &:nth-of-type(8) { width: $cw7; }
        &:last-of-type { padding-right: 2rem; }
      }
    }

    td { text-align: right;

      @include media(992px) { text-align: left; }
    }
  }
}

#actionsBlock {
  @include flexbox( $jc: flex-end );
  width: auto;

  button { margin-left: auto; }
}

#metrics {
  &Nums {
    @include flexbox( $ai: flex-start, $fd: column, $jc: space-between );
    width: 100%;

    @include media(576px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .item {
      @include flexbox( $fd: column, $jc: center );
      margin-bottom: 3rem;
      width: 100%;

      @include media(576px) { width: 50%; }
      @include media(768px) { width: 25%; }
      @include media(1200px) {
        margin-bottom: 0;
        width: 16.6666666667%;
      }

      h3 { text-align: center; }
    }
  }
}

#glActions {
    @include flexbox( $ai: flex-start, $fd: column, $jc: space-between );

    @include media(576px) {
        align-items: center;
        flex-direction: row;
    }

    > button { margin-bottom: 3rem;

        @include media(576px) { margin-bottom: 0; }
    }

    > div {
        margin-left: auto;
        width: auto;
    }
}

#locationsTable {
  @extend #customersTable;

  .actions {
    @include flexbox( $jc: space-between );
    gap: 1rem;
    width: 100%;

    button {
      padding-right: 1rem!important;
      padding-left: 1rem!important;

      @include media(576px) {
        margin-right: auto;
        margin-left: auto;
      }
      @include media(992px) { margin-right: 0; }

      &:first-of-type {
        @include media(992px) { margin-bottom: 0; }
      }
      &:last-of-type {
        @include media(992px) { margin-left: 0; }
      }
    }
  }
}
