@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.chat {
  
  &Block {
    @include flexbox($ai: flex-start, $fd: column);
    background-color: #F6F6F6;
    flex: 2;
    height: calc(100vh - 6rem);

    > div {
      width: 100%;
    }
  }

  &Header {
    @include relative($z: 2);
    @include flexbox($fd: row, $jc: center);
    background-color: #fff;
    height: 7rem;

    .spacer {
      flex: 1;
      padding-left: 2rem;
    }
    .idActions {
      @include flexbox($jc: center, $gap: 0.3rem);
      @include font($family: 'Inter', $size: 20px);
      flex: 2;
      text-align: center;
    }
    .actions {
      @include absolute($right: 2rem);
      @include flexbox($jc: flex-end, $gap: 1rem);
      flex: 1;
      margin-left: auto;
    }

    &::before {
      @include absolute($top: 100%, $right: 0, $left: 0);
      @include opacity(0.05);
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      height: 16px;
    }
    // &::after {
    //   @include absolute($right: 0, $bottom: 0, $left: 0);
    //   background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, #000 100%);
    //   content: '';
    //   height: 16px;
    // }
  }

  &Display {
    @include relative;
    background-color: #fff;
    flex: 1;
    overflow-y: scroll;
    padding: 20px;

    .message {
      @include flexbox($gap: 1.5rem);
      max-width: 75%;

      .messageGroup {
        @include flexbox($ai: flex-start, $fd: column, $gap: 0.5rem);
        
        .name {
          @include font($family: 'Inter',$weight: 700);
          line-height: 100%;
        }
        .blurb {
          @include font($family: 'Inter');
          @include relative;
          background-color: #efefef;
          border-radius: 1.5rem;
          color: #363137;
          line-height: 150%;
          padding: 1rem;

          > svg {
            @include absolute($bottom: 0, $left: -0.2rem);
          }
        }
        .time {
          @include font($family:'Inter',$size:10px);
          color: #979797;
          margin-left: 0.5rem;
        }
      }

      &.right {
        justify-content: flex-end;
        margin-left: auto;

        > img {
          order: 1;
        }

        .messageGroup {
          align-items: flex-end;
          justify-content: flex-end;
          order: 0;
          
          .blurb {
            background-color: #3099fd;
            color: #fff;

            > svg {
              @include absolute($right: -0.2rem, $bottom: 0, $left: auto);
            }
          }
          .time {
            margin: {
              right: 0.5rem;
              left: auto;
            }
          }
        }
      }
    }
  }

  &Actions {
    @include flexbox($jc: space-between, $gap: 2rem);
    @include relative($z: 2);
    background-color: #fff;
    padding: 2rem 2rem 3rem;
    width: 100%;
    
    &::after {
      @include absolute($right: 0, $bottom: 100%, $left: 0);
      @include opacity(0.05);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      content: '';
      height: 16px;
    }

    .chat {

      &Input {
        border-color: #3099fd;
        border-radius: 1.7rem;
        flex: 1;
        height: 3rem;
      }

      &Microphone {
        @include flexbox($jc: center);
        border-radius: 1.7rem;
        height: 3rem;
        width: 4.2rem;
      }
    }

    .send {
      height: 3rem;
      line-height: 3rem;
      min-width: 10.2rem;
    }
  }
}
