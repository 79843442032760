.btn {
  @include font($inter, 12px, 700);
  box-shadow: none!important;
  height: 3.4rem;
  line-height: 3.4rem;
  padding: 0 1rem;
  transition: .2s ease-in-out;
  
  &.btn {
    
    // background-color: $brightBlue
    &-primary,
    &-outline-primary:hover { background-color: $brightBlue!important; }

    // background-color: $medBlue
    &-primary:hover { background-color: $medBlue!important;
    
      // icon
      > svg path { fill: $white!important; }
    }
    
    // background-color: $gray56
    &-dark { background-color: $gray56; }
    
    // background-color: $gray182
    &-cancel:hover { background-color: $gray182; }
    
    // background-color: $green
    &-success,
    &-outline-success:hover { background-color: $green; }

    // background-color: $negTrend
    &-danger:hover,
    &-outline-danger:hover { background-color: $negTrend!important; }

    // background-color: $red
    &-danger { background-color: $red!important; }
    
    // background-color: $white
    &-outline-dark,
    &-outline-danger,
    &-outline-success,
    &-outline-primary,
    &-cancel { background-color: $white; }
    
    // border-color: $brightBlue
    &-primary,
    &-outline-primary,
    &-outline-primary:hover { border-color: $brightBlue!important; }

    // background-color: $medBlue
    &-primary:hover { border-color: $medBlue!important; }

    // background-color: $negTrend
    .btn.btn-danger:hover { background-color: $negTrend; }
    
    // border-color: $gray56
    &-dark,
    &-outline-dark { border-color: $gray56; }

    // border-color: $gray182
    &-cancel { border-color: $gray182; }
    
    // border-color: $green
    &-success,
    &-outline-success,
    &-outline-success:hover { border-color: $green; }

    // border-color: $negTrend
    &-danger:hover { border-color: $negTrend; }

    // border-color: $red
    &-danger,
    &-outline-danger,
    &-outline-danger:hover { border-color: $red; }
    
    // color: $brightBlue
    &-outline-primary,
    &-link { color: $brightBlue!important;
    
      // icon
      > svg path { fill: $brightBlue; }
    }

    // color: $medBlue
    &-link:hover { color: $medBlue!important; }
    
    // color: $gray56
    &-outline-dark { color: $gray56; }

    // color: $gray56
    &-cancel { color: $gray182; }
    
    // color: $green
    &-outline-success { color: $green; }
    
    // color: $red
    &-outline-danger { color: $red; }
    
    // color: $white
    &-outline-danger:hover,
    &-dark,
    &-primary,
    &-outline-primary:hover,
    &-success,
    &-outline-success:hover,
    &-danger,
    &-cancel:hover { color: white!important; }
    
    // fill: $white
    &-outline-primary:hover svg path { fill: $white; }
    
    
    &-link {
      @include font-size( 14px );
      border: 0;
      height: auto;
      line-height: 1.4rem;
      padding: 0;
      text-decoration: none;
    }
    
  }
}

header .btn.btn-outline-danger { background-color: transparent; }


/** Button Group ----- */
.btn-group {
  @include border( $width: 0.1rem, $color: $gray56 );
  @include border-rad( 0.2rem );
  background-color: $white;
  flex-direction: column;
  width: 100%;

  @include media(400px) {
    flex-direction: row;
    // width: auto;
  }

  button {
    @include font( $size: 12px );
    @include relative;
    background-color: transparent;
    border: 0;
    height: 3rem;
    min-width: 10.1rem;
    padding: 0;
    width: 100%!important;

    &::after {
      @include absolute( $right: 0, $bottom: 0, $left: 0 );
      background-color: $gray56;
      content: '';
      height: 0.1rem;

      @include media(400px) {
        height: auto;
        top: 0;
        left: auto;
        width: 0.1rem;
      }
    }
    
    &:last-of-type:after { display: none; }
    
    &.active {
      background-color: $gray56;
      color: $white;    
      font-weight: 700;
    }
  }

  &.pills {
    border: none;
    gap: 10px;
    margin-left: auto;
    width: auto;

    button {
      @include border-rad(15px);
      border: 1px solid $navy;
      min-width: 100px;
      text-transform: capitalize;

      &::after {
        display: none;
      }

      &.active {
        background-color: $navy;
      }
    }
  }

  &.gridList {
    border: 0;
    width: auto;

    button {
      @include css3-prefix(transition, 0.15s all);
      border: {
        color: $brightBlue;
        style: solid;
      }
      min-width: 50px;

      &:first-of-type {
        border: {
          top-left-radius: 4px;
          bottom-left-radius: 4px;
          width: 1px 0 1px 1px;
        }
      }
      &:last-of-type {
        border: {
          top-right-radius: 4px;
          bottom-right-radius: 4px;
          width: 1px 1px 1px 0;
        }
      }

      &::after {
        display: none;
      }

      &:hover {
        background-color: $medBlue!important;
        border-color: $medBlue!important;

        svg path {
          fill: $white;
        }
      }

      &.active {
        background-color: $brightBlue!important;
        border-color: $brightBlue!important;

        svg path {
          fill: $white;
        }
      }
    }
  }
}
