@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.cattleDetailsNote {
  @include bd-bottom($width: 1px, $color: $gray246);
  @include flexbox(flex-start, row, flex-start, wrap, 8px);
  @include pady(10px);
}

.labelBlock,
.timespan,
.desc {
  color: $gray56;
  font-size: 12px;
}
.labelBlock,
.timespan {
  line-height: 100%;
}
.labelBlock {
  @include flexbox($gap: 7px);
  font-weight: 700;
  margin-top: 0!important;

  .dot {
    @include border-rad(4px);
    @include square(8px);
  }
}

.timespan {
  margin-left: auto;
}

.desc {
  margin-top: 7px;
}
.author {
  color: $gray149;
  font-size: 12px;
  line-height: 100%;
  margin: {
    top: -10px;
    left: auto;
  };
}