// file: ./scss/abstracts/_variables.scss

// colors
$black:         #000000;
$gray56:        #383838;
$gray73:        #494949;
$gray79:        #4F4F4F;
$gray116:       #747474;
$gray136:       #888888;
$gray149:       #959595;
$gray182:       #B6B6B6;
$gray196:       #c4c4c4;
$gray207:       #CFCFCF;
$gray217:       #D9D9D9;
$gray221:       #DDDDDD;
$gray222:       #DEDEDE;
$gray226:       #E2E2E2;
$gray243:       #F3F3F3;
$gray246:       #F6F6F6;
$gray248:       #F8F8F8;
$gray250:       #FAFAFA;
$white:         #FFFFFF;

$bg:            #EFECEC;

$header:        #1D1B19;
$navActive:     #252320;
$blueHome:      #027BA7;
$navy:          #0D1D27;//#29313B;
$navyDark:      #1F262F;
$blueHomeBlack: #1C2229;
$blue:          #3099FD;
$blueHover:     #278AEA;
$blueGray:      #9BB1CC;
$blueRowHover:  #D6EBFF;
$medBlue:       #223C4E;
$brightBlue:    #3E9CDB;
$sideBarActive: #385F78;

$submenuBg:     #343F4B;
$submenuBgH:    #3E4B5A;
$menuBg:        #3C4956;
$menuBgH:       #465665;
$menuCopy:      #B1C0D0;

$greenHome:     #64B08B;
$yellowGreen:   #C4E36E;
$green:         #83D75C;
$posTrend:      #73D451;

$red:           #F16060;
$negTrend:      #E35B5B;
$asterisk:      #A00D06;

$gold:          #FFC700;
$goldTan:       #EBBF4F;

$primary-color: #3e9cdb;
$hover-color: darken($primary-color, 10%);
$focus-ring-color: rgba($primary-color, 0.25);
$error-color: #dc3545;




// fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

$inter: 'Inter', sans-serif;