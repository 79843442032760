// file: client/src/components/SortableTableHeader/styles.module.scss

@import '../../scss/imports';

.label {
    cursor: pointer;
    position: relative;
}

.header {
    user-select: none;
    cursor: pointer;
    @include bd-bottom( $width: 0.1rem, $color: $gray182 );
    padding: 0 3rem;
    background-color: inherit;

    @include media(992px) {
      @include font-size( 10px );
      padding-right: 2rem;// 1rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;// 1rem;
    }
    @include media(1200px) {
      @include font-size( 12px );
      padding-right: .5rem;
      padding-left: .5rem;
    }
}

.sortAsc {
    transform: rotate(180deg);
}
