// file: ./src/scss/base/_accordions.scss

/* Accordion */
.accordion {
  @include flexbox( $fd: column );
  --bs-accordion-color: $gray56;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0.8rem;
  --bs-accordion-inner-border-radius: 0.8rem;
  --bs-accordion-btn-padding-x: 1.2rem;
  --bs-accordion-btn-padding-y: 0.5rem;
  --bs-accordion-btn-color: $white;
  --bs-accordion-btn-bg: $gray56;
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 2rem;
  --bs-accordion-body-padding-y: 3rem;
  --bs-accordion-active-color: $white;
  --bs-accordion-active-bg: $gray56;

  gap: 3rem;
  margin-bottom: 4rem;
  outline: none;
  
  .accordion-item {
    @include border-rad( .8rem );
    border: 0;
    margin-bottom: 0;
    width: 100%;
  }
  
  .accordion-header {
    border: 0;
    padding: 0;
    
    .accordion-button {
      @include border-rad( .8rem .8rem 0 0 );
      @include flexbox( flex-start, column, center );
      @include font( $inter, 14px, 700 );
      @include relative;
      background-color: $gray56;
      box-shadow: none;
      height: 5rem;
      line-height: 100%;
      padding: 1.6rem 1.2rem 1.6rem 4.3rem;
      z-index: 1;

      @include media(576px) {
        @include flexbox( center, row, space-between );
      }
      @include media(768px) {
        @include font-size( 18px );
      }

      &.collapsed {
        @include border-rad( 0.8rem );
      }
      
      &.show {
        @include border-rad( 0.8rem 0.8rem 0 0 );

        .icon .bar:first-of-type {
          @include css3-prefix( transform, rotate(0deg) );
          top: 43%;
          left: 23%;
        }
      }
      
      &::after { display: none; }
      
      .icon {
        @include absolute( $top: 50%, $left: 1.2rem );
        @include border( $width: 0.1rem, $color: $white );
        @include border-rad( 50% );
        @include css3-prefix( transform, translateY(-50%) );
        @include square( 1.6rem );
        
        .bar {
          @include absolute( $top: 50%, $left: 50% );
          @include border-rad( .1rem );
          @include css3-prefix( transform, translate(-50%, -50%) );
          @include css3-prefix( transition, .1s );
          background-color: $white;
          height: 0.2rem;
          width: 0.8rem;
          
          &:first-of-type {
            @include css3-prefix( transform, translate(-50%,-50%) rotate(90deg) );
          }
        }
      }

      > span {
        @include flexbox;
      }
    }

    p {
      @include font-size( 10px );
      
      &.infoBlock {
        @include font-size( 16px );
        
        @include media(576px) { margin-right: 7.5rem; }
        @include media(768px) { margin-right: 18rem; }
      }
    }

    .image {
      @include border-rad( 0.4rem );
      @include square( 3rem );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 1.5rem;
    }
    
    .actions {
      @include absolute( $top: 50%, $right: 1rem, $z: 100 );
      @include css3-prefix( transform, translateY(-50%) );
      @include flexbox;
      
      .action {
        @include border-rad( 0.4rem );
        @include css3-prefix( transition, .25s ease-in-out );
        @include flexbox( $jc: center );
        @include relative;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        height: 4rem;
        margin-right: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        width: 2.5rem;

        @include media(768px) {
          margin-right: 2rem;
          width: 4rem;
        }
        
        &:last-of-type { margin-right: 0; }
        
        &:hover,
        &.active { background-color: $gray79; }
        
        .dot {
          @include absolute( $top: 1rem, $right: 1rem );
          @include border( $width: 0.1rem, $color: $gray79 );
          @include border-rad( 50% );
          @include square( 0.8rem );
          background-color: $red;
        }
      }
    }
    
    .popup {
      @include absolute( $top: calc(100% + 1.4rem), $z: 101 );
      @include border( $width: 0.1rem, $color: $gray196 );
      @include border-rad( 0.4rem );
      @include css3-prefix( transform, translateY(0) );
      @include css3-prefix( transition, .25s ease-in-out );
      background-color: $white;
      filter: drop-shadow(0.4rem 0.4rem 0.8rem rgba(0, 0, 0, 0.15));
      font-size: 1rem;
      opacity: 1;
      padding: 1.5rem 1rem 1rem;
      
      &.hide {
        @include css3-prefix( transform, translateY(3.5rem) );
        opacity: 0;
        z-index: -1;
      }
      
      &::before,
      &::after {
        @include absolute;
        @include border( $el: x, $width: 0.7rem, $color: transparent );
        @include pseudo();
        height: 0;
        width: 0.6rem;
        transform: rotate(45deg);
      }
      &::before {
        @include bd-bottom( $width: 0.6rem, $color: $gray196 );
        top: -0.4rem;
      }
      &::after {
        @include bd-bottom( $width: 0.6rem, $color: $white );
        top: -0.3rem;
      }
      
      &.settings {
        @include flexbox( $fd: column );
        gap: 1rem;
        right: 2rem;
        width: 20rem;
        
        &::before,
        &::after { right: 1.2rem; }
      }
      
      &.alerts {
        padding: 1.5rem 2rem;
        right: 4rem;
        width: 31rem;
        
        &::before,
        &::after { right: 5rem; }
      }
      
      .title {
        @include font( $inter, 10px, 700 );
        line-height: 1.4rem;
        margin-bottom: 0;
      }

      .item {
        width: 100%;
  
        .formRange {
          border: 0;
          height: auto;
          padding: 0;
        }
      }

      .desc {
        @include font( $inter, 10px, 400 );
        line-height: 150%;
        margin-bottom: 0;
      }
      
      button {
        @include font( $inter, 10px, 400 );
        width: 100%;
      }
    }
    
    .table {
      .thead {
        @include bd-bottom( $width: 0.1rem, $color: $menuCopy );
        margin-bottom: 0.3rem;
        
        .th {
          @include font( $inter, 10px, 700 );
          color: $gray56;
          line-height: 100%;
          margin-bottom: 0.5rem;
        }
      }
      
      .tbody {
        .td {
          @include font( $inter, 12px, 400 );
          box-shadow: none!important;
          color: $gray56;
          line-height: 100%;
          padding: 0.5rem 0!important;
        }
      }
    }
  }
  
  &-body {
    @include border-rad( 0 0 0.8rem 0.8rem );

    .dataBlock {
      .data {
        @include border-rad( 0.8rem );
        padding: 3rem 1.5rem;

        @include media(576px) { padding: 3rem 1rem; }
      }
      
      .title,
      label {
        @include font( $inter, 14px, 700 );
        margin-bottom: 1rem;
      }
      label { margin-bottom: 0; }
      
      .item {
        @include font( $inter, 30px, 300 );
        line-height: 3.4rem;
      }
    }
  }
}