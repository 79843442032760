@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.container {
  @include flexbox($ai: flex-start);
  background-color: $white;

	.sidebar {
    @include fixed($top: 6rem, $bottom: 0, $left: 0);
		background-color: $navy;//#1B2026;
    width: 200px;

    @include media(1100px) {
		  width: 240px;
    }

    .title {
      @include flexbox($jc: space-between);
      @include font($family: 'Inter', $size: 14px, $weight: 600);
      background-color: #223C4E;//#29313B;
      border-top: 0.1rem solid $navy;
      color: $white;
      line-height: 100%;
      padding: 1.5rem 1rem;
      
      @include media(1100px) {
        @include font($family: 'Inter', $size: 18px, $weight: 600);
        padding: 1.5rem 1rem 1.5rem 2rem;
      }

      > svg { display: none; }
    }

    .pen {
      @include flexbox($jc: space-between);
      @include font($family: 'Inter', $size: 11px, $weight: 700);
      @include relative;
      background-color: $medBlue;//#29313B;
      border-top: 0.1rem solid $navy;//#1B2026;
      color: $white;
      cursor: pointer;
      line-height: 100%;
      padding: 1.5rem 1rem;

      @include media(1100px) {
        @include font($family: 'Inter', $size: 12px, $weight: 700);
        padding: 1.5rem 1rem 1.5rem 2rem;
      }

      &::after {
        @include absolute($top: 0, $right: 0, $bottom: 0);
        background-color: $brightBlue;
        content: '';
        width: 3px;
      }

      &.active {
        background-color: $sideBarActive;
      }

      .length {
        @include flexbox($jc: center);
        @include font($family: 'Inter', $size: 10px, $weight: 700);
        background-color: $navy;
        border-radius: 1.5rem;
        height: 1.8rem;
        line-height: 100%;
        min-width: 2.9rem;
      }
    }

    .infoBlock {
      $pH: 16px;
      $pV: 24px;

      @include absolute($right: 0, $bottom: 0, $left: 0);
      @include flexbox($ai: flex-start, $fd: column, $gap: 24px);
      background-color: $medBlue;
      color: $white;
      padding: {
        top: $pV;
        bottom: $pV;
        right: $pH;
        left: $pH;
      }

      .top {
        @include flexbox($ai: flex-start, $fd: column, $gap: 5px);
        color: $white;
        font: {
          family: 'Inter';
          size: 12px;
          weight: 500;
        }
        line-height: 16px;

        .name {
          font: {
            family: 'Inter';
            size: 14px;
            weight: 700;
          }
        }
      }

      > button {
        @include css3-prefix(transition, 0.15s all);
        background-color: transparent;
        border: {
          color: $brightBlue;
          radius: 4px;
          style: solid;
          width: 3px;
        }
        color: $brightBlue;
        font: {
          family: 'Inter';
          size: 16px;
          weight: 600;
        }
        padding: 8px;
        width: 100%;

        &:hover {
          background-color: $brightBlue;
          color: $white;
        }
      }
    }
	}

	.content {
    @include flexbox($ai: flex-start, $fd: column, $gap: 25px);
    flex: 1;
    padding: 2rem 1rem 2rem 21rem;
    width: 100%;

    @include media(1100px) {
		  padding: 3rem 3rem 3rem 27rem;
    }
    @include media(1200px) {
		  padding: 3rem 4rem 3rem 28rem;
    }

    > div {
      width: 100%;
    }

		.header {
      @include flexbox($ai: flex-start, $fd: column, $jc: space-between, $gap: 20px);

      @include media(992px) {
        @include flexbox($jc: space-between);
      }

      .buttonFilters {
        @include flexbox($gap: 9px);

        > button {
          @include font($family: 'Inter', $size: 12px);
          background-color: transparent;
          border: 0.1rem solid #666;
          border-radius: 1.5rem;
          color: #666;
          height: 3rem;
          min-width: 10rem;

          &.active {
            background-color: #666;
            color: #fff;
            font-weight: 700;
          }
        }
      }
		}

		.infobar {
      @include flexbox($ai: flex-start, $fd: column, $jc: space-between, $gap: 2rem);
      border-bottom: 0.1rem solid #d9d9d9;
      padding-bottom: 2rem;

      @include media(1400px) {
        @include flexbox($jc: space-between, $gap: 2rem);
      }

      .group {
        @include flexbox($ai: flex-start, $jc: space-between, $gap: 1rem, $fw: wrap);
        background-color: #F6F6F6;
        border-radius: 0.4rem;
        padding: 1.5rem 2rem;
        width: 100%;

        .item {
          @include flexbox($ai: flex-start, $fd: column, $gap: 1rem);

          > h3 {
            @include font($family: 'Inter', $weight: 700);
            line-height: 100%;
            margin: 0;
          }
        }

        &:first-of-type {
          flex-direction: column;
          flex: 3;
          gap: 0;

          @include media(1100px) {
            flex: {
              direction: row;
              wrap: nowrap;
            }
            gap: 10px;
          }

          .item {
            @include flexbox;
            border-bottom: 1px solid #fff;
            padding: 8px 0;
            width: 100%;

            @include media(1100px) {
              @include flexbox($ai: flex-start, $fd: column);
              border: 0;
              padding: 0;
            }

            h3 {
              width: 125px;
            }
          }
        }
        &:last-of-type {
          flex: 1;

          .item {
            align-items: center;
            flex: 1;
          }
        }
      }
		}

		.cattleSection {
      @include flexbox($ai: flex-start, $fd: column, $gap: 2rem);

      .lengthView {
        @include flexbox($jc: space-between);
        width: 100%;
      }
      .length {
        @include font($family: 'Inter', $size: 16px);
        color: $navy;
        line-height: 100%;

        @include media(1100px) {
          @include font($family: 'Inter', $size: 20px);
        }
      }
			.cattleCards {
        @include flexbox($ai: flex-start, $fw: wrap, $jc: flex-start, $gap: 2rem);
        width: 100%;
        height: 240px;

        // @include media(1100px) {
        //   gap: 25px;
        //   // justify-content: space-between;
        // }
        // // @include media(1200px) {
        // //   gap: 30px;
        // // }
        @include media(1400px) {
          gap: 14px;
        }
        @include media(1500px) {
          gap: 17px;
        }
        @include media(1800px) {
          gap: 19px;
        }
        @include media(1900px) {
          gap: 21px;
        }

        &::after {
          @include media(1400px) {
            content: '';
            flex: auto;
          }
        }

        &.list {
          @include flexbox($ai: flex-start, $fd: column);
        }
			}
		}
	}
}

.cattleCard {
  @include border($width: 1px, $color: $gray217);
  @include border-rad(8px);
  @include flexbox($ai: flex-start, $fd: column, $gap: 1rem);
  flex-basis: 48%;

  @include media(992px) {
    flex-basis: 31%;
  }
  @include media(1400px) {
    flex-basis: 23.92%;
  }
  @include media(1600px) {
    flex-basis: 24%;
  }
  @include media(1800px) {
    flex-basis: 24.037%;
  }
  @include media(1900px) {
    flex-basis: 24%;
  }
  // flex: 1 1 30%;
  // max-width: 31%;

  // @include media(1300px) {
  //   flex: 1 1 25%;
  //   max-width: 25%;
  // }
  // width: 48%;

  // @include media(992px) {
  //   width: 31.607%;
  // }
  // @include media(1100px) {
  //   width: 31.25%;
  // }
  // @include media(1200px) {
  //   width: 31.43%;
  // }
  // @include media(1500px) {
  //   width: 23.41%;
  // }

  .list & {
    max-width: none;
    min-width: 0;
    width: 100%;
  }

  > div {
    width: 100%;
  }

  .titleBlock {
    @include flexbox($jc: space-between, $gap: 10px, $fw: wrap);
    padding: 15px 10px 0;

    @include media(1100px) {
      padding: 20px 20px 0;
    }
    
    > div {
      @include font($family: 'Inter', $size: 12px, $weight: 700);
      color: #383838;
      flex: 1;
      line-height: 100%;

      &:first-child {
        color: $gray149;
        flex: unset;
        font-weight: 400;
        width: 100%;
      }
      &:nth-child(2) {
        @include media(1100px) {
          text-align: center;
        }
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
  .image {
    @include relative;
    background: {
      color: $gray250;
      position: center;
      repeat: no-repeat;
      size: cover;
    };
    border-radius: 0.4rem;
    height: 22rem;
    margin: {
      right: 10px;
      left: 10px;
    };
    width: calc(100% - 20px);

    @include media(1100px) {
      margin: {
        right: 20px;
        left: 20px;
      };
      width: calc(100% - 40px);
    }

    > button {
      @include absolute(0,0,0,0);
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  .infoBlock {
    @include flexbox($jc: flex-start, $gap: 1rem);
    background-color: $gray246;
    height: 2.8rem;
    margin: {
      right: 10px;
      left: 10px;
    };
    padding: 0.8rem 1rem;
    width: calc(100% - 20px);

    @include media(1100px) {
      margin: {
        right: 20px;
        left: 20px;
      };
      width: calc(100% - 40px);
    }

    > span {
      @include font($family: 'Inter', $size: 12px);
      flex: 1;
      line-height: 100%;

      &:nth-of-type(2) {
        text-align: center;
      }
    }

    .urgent {
      @include flexbox($jc: flex-end, $gap: 1rem);
      // color: #ee3253;
      // flex: 2;
      font-weight: 700;
    }
  }
  .notes {
    @include flexbox($ai: flex-start, $fd: column, $gap: 0.6rem);
    margin: 10px 10px 15px;
    width: calc(100% - 20px);

    @include media(1100px) {
      margin: 10px 20px 15px;
      width: calc(100% - 40px);
    }

    .label {
      @include font($family: 'Inter', $size: 12px, $weight: 700);
      color: $navy;
      line-height: 100%;
    }
    > p {
      @include font($family: 'Inter', $size: 12px);
      line-height: 130%;
    }
  }
  .actions {
    border-bottom: {
      right-radius: 8px;
      left-radius: 8px;
    };
    background-color: #F6F6F6;
    border-top: 0.1rem solid #d9d9d9;
    padding: 2rem;

    > button {
      @include css3-prefix(transition, 0.15s all);
      @include font($family: 'Inter', $weight: 700);
      @include border($width: 3px, $color: $brightBlue);
      background-color: $brightBlue;
      border-radius: 0.4rem;
      color: #fff;
      height: 3.4rem;
      line-height: 100%;
      width: 100%;

      &:hover {
        @include border($width: 3px, $color: $medBlue);
        background-color: $medBlue;
      }
    }
  }


// 					.image {

// 					}

// 					.infoBlock {
// 						display: flex;
// 						flex-direction: row;
// 					}

  &.urgent {
    border-color: #EE3253;

    .actions {
      background-color: #EE3253;
      border-color: #EE3253;

      > button {
        background-color: #fff;
        color: #EE3253;
      }
    }
  }
}
