// file: client/src/Admin/Topbar/styles.module.scss

@import '../../scss/imports';

$h: 6rem;

.dropdownContainer {
  @include fixed(
    $right: 0,
    $bottom: 0,
    $left: 0,
  );
  border: {
    bottom: 0!important;
    top: 0.1rem solid rgb(255 255 255 / 15%);
  }
  cursor: pointer;
  height: auto;
  line-height: $h;
  padding: 0;
  user-select: none;
  width: auto!important;

  @include media(768px) {
    @include relative;
    height: $h;
    padding: {
      right: 2rem;
      left: 2rem;
    }
  }
  @include media(992px) {
    padding: {
      right: 3.3rem;
      left: 3.3rem;
    }
  }

  &:hover {
    @include media(768px) {
      background-color: $white;
    }
  }

  .dropdown {
      @include border-rad(0 0 0 0.3rem);
      @include css3-prefix(
        transition,
        .15s ease-in-out
      );
      @include flexbox(
        $ai: flex-start,
        $fd: column,
      );
      @include static;
      background-color: $navy;
      color: white;
      width: 100%;

      @include media(768px) {
        @include absolute(
          $top: 6.9rem,
          $right: 0,
          $z: -999,
        );
        @include css3-prefix(
          transition,
          .15s ease-out
        );
        @include opacity(0);
        visibility: hidden;
        width: 20rem;
      }

      &.open {
        @include opacity;
        top: 5.9rem;
        visibility: visible;
      }

      &::before {
          @include absolute(0, 0, 0, 0, -1);
          background-color: rgb(0 0 0 / 15%);
          content: '';
      }

      > {
          * { width: 100%; }
  
          div {
              @include flexbox(
                $ai: flex-start,
                $fd: column,
                $gap: 0.5rem
              );
  
              label { color: $menuCopy; }
  
              select { width: 100%; }
          }
      }

      h5 {
        @include font($weight: 600);
        border-bottom: 0.1rem solid rgb(255 255 255 / 8%);
        color: $white;
        cursor: default;
        margin-bottom: 0;
        padding: 1rem;

        span.email {
          @include font($size: 10px, $weight: 500);
          color: $blueGray;
          display: block;
        }
      }

      a.link {
        @include css3-prefix(transition, 0.15s);
        border-bottom: 0.1rem solid rgb(255 255 255 / 8%);
        color: rgb(255 255 255 / 90%)!important;
        font-size: 1.2rem!important;
        font-weight: 600!important;
        height: auto!important;
        line-height: 100%!important;
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
        text-align: left!important;

        &::after {
          display: none;
        }

        &:hover {
          background-color: $blueGray;
          color: $white!important;
        }
      }
  }

  svg {
    display: none;

    @include media(768px) {
      @include square(1.8rem);
      display: block;
    }
  }

  .locSelect {
    padding: 1rem;

    h5 {
      @include font($size: 10px, $weight: 500);
      border: 0;
      color: $blueGray;
      line-height: 100%;
      padding: 0;
    }

  }
  button {
    @include border-rad(0);
  }
}
