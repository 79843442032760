@use 'sass:color';

@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.content {
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  flex: 1;
  overflow-y: scroll;
  padding: 8px;
  width: 100%;

  p {
    font: {
      size: 12px;
      weight: 500;
    };
    line-height: 18px;
    margin-bottom: 10px;
  }
}
