@use 'sass:color';

@import '../scss/abstracts/variables';
@import '../scss/abstracts/mixins';

.paneLogin {
  background-color: #fff;
  display: flex;
  height: 100%;
  margin: {
    left: auto;
    right: auto;
  };
  padding-bottom: 0;
  width: 100%;

  .paneInner {
    @include flexbox(center, column, flex-start, nowrap, 0);
    @include padx(16px);
    @include pady(16px);
    width: 100%;

    .logo {
      margin-bottom: 16px;

      span {
        border-width: 1px;
        height: 32px;
        padding: 4px;
        width: 32px;

        @include media(768px) {
          height: 64px;
          width: 64px;
        }
      }
    }

    .actions {
      @include flexbox(center, row, center, nowrap, 8px);
      padding-top: 16px;
      width: 100%;
    }
  }
}