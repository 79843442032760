// file: client/src/scss/base/_typography.scss

h1,
h2,
h3 { line-height: 100%; }

h1 {
  @include font( $inter, 18px, 700 );
  color: #383838;
  margin: 0;

  @include media(992px) {
    @include font( $inter, 24px, 700 );
  }
}

h2 {
  border: 0;
  @include bd-bottom( $width: 0.1rem, $color: $gray56 );
  @include font( $inter, 18px, 700 );
  padding-bottom: 1rem;
  width: 100%;
}

h3 { @include font( $inter, 14px, 700 ); }

p {
  @include font( $inter, 14px, 400 );
  line-height: 150%;

  &:last-of-type { margin-bottom: 0; }
}

ul,
ol {
  &:last-of-type { margin-bottom: 0; }
}

a {
  @include css3-prefix( transition, .1s );
  color: $blue;
  font-weight: 700;
  text-decoration: none;
}
