.canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.modalCanvas {
    margin-bottom: -4px;
    position: relative;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.viewer {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.btn {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100%;
    height: 32px;
    width: 32px;
    border: none;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.btnContainer {
    padding: 8px;
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    background-color: rgba(0, 0, 0, 0.3);
}

.btnContainer > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.imgContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.videoContainer {
    position: relative;
    aspect-ratio: 1 / 1;
    /* height: 100%; */
    width: 100%;
}

.videoContainer.contain {
    overflow: hidden;
}

@media screen and (min-width: 992px) {
  .videoContainer {
    height: 100%;
    width: unset;
  }
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

button.retry {
  border: 1px solid #3099fd;
  color: #3099fd;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 20px;
}

.loader {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #888;
    animation: spin 1s infinite linear;
    margin: 0 auto;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.deviceSelect {
    width: 300px;
}

.displaySelect {
    width: 140px;
}
