@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.headerGraph {
  @include flexbox($fd: column);
  flex: 1;
  width: 100%;

  @include media(950px) {
    flex: unset;
    min-width: 450px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.monitoringAreaList {
  // list-style: none;
  // list-style-position: outside;
  // padding-left: 0px;
  // text-decoration: none;
  
  flex: 1;
  overflow-y: auto;
}

.monitoringAreaList a {
  color: #383838;
}

.monitoringDeviceList {
  list-style: none;
  list-style-position: outside;
  padding-left: 12px;
}

.headerInfo {
  @include flexbox($ai: flex-start, $fd: column, $gap: 20px);

  @include media(950px) {
    flex-direction: row;
  }
  @include media(1100px) {
    @include flexbox($ai: center, $jc: space-between, $gap: 2rem);
  }

  &.map {
    justify-content: flex-start;
  }

  > * {
    background-color: #f6f6f6;
    border-radius: 0.4rem;
    flex: 1;
    height: 14.5rem;
    padding: 2rem;
  }

  .stats {
    @include flexbox($ai: center, $jc: space-around, $gap: 2rem);
    // padding-left: 5rem;

    &.map {
      flex: unset;
      width: 100%;

      @include media(992px) {
        min-width: 60rem;
      }
    }

    &.health {
      // flex: unset;
      min-width: 35rem;
    }

    .stat {
      @include flexbox($ai: center, $fd: column, $gap: 0.5rem);

      .num {
        @include font($family: 'Inter', $size: 28px, $weight: 700);
        color: #383838;
        line-height: 3.2rem;
      }
      .sub {
        @include font($family: 'Inter', $size: 20px);
        line-height: 3.2rem;
      }
      .label {
        @include font($family: 'Inter', $weight: 700);
        text-align: center;
      }
    }
  }

  .graph {
    flex: unset;
    min-width: 45rem;
    width: 100%;

    @include media(1100px) {
      max-width: 60rem;
    }

    > div {
      max-width: 100%!important;

      > h3 {
        margin-bottom: 0;
      }
      > canvas {
        max-height: 8rem!important;
      }
    }
  }
}

.filterRow {
  @include flexbox($ai: flex-end, $jc: space-between, $fd: row, $gap: 1rem);
  width: 100%;
}

.filterRowItem {
  @include flexbox($ai: flex-start, $fd: row);
  // flex-basis: 33%;
  width: auto;//100%;

  &.right {
    justify-content: flex-end;

    &.btnGroup {
      display: flex;

      > button {
        @include flexbox($ai: center, $jc: center, $gap: 1rem);
        @include font($family: 'Inter', $size: 12px);
        background-color: #fff;
        border: 0.1rem solid #383838;
        color: #383838;
        font-weight: 400;
        height: 3rem;
        margin-left: -0.1rem;
        padding: 0;
        width: 10rem;

        &.btnPrimary {
          background-color: #3E9CDB!important;
          border-color: #3E9CDB!important;
        }
        &.btnOutlinePrimary:hover {
          background-color: #0D1D27!important;
          border-color: #0D1D27!important;
        }

        .dot {
          @include square(0.8rem);
          border-radius: 0.4rem;
          background-color: #767B80;

          &.yellow {
            background-color: #EBBF4F;
          }
          &.red {
            background-color: #F16060;
          }
        }

        &.active {
          background-color: #383838;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
  &.vert {
    flex-direction: column;

    @include media(992px) {
      flex: 1;
    }

    .btnGroup {
      display: flex;

      > button {
        @include flexbox($ai: center, $jc: center, $gap: 1rem);
        @include font($family: 'Inter', $size: 12px);
        background-color: #fff;
        border: 0.1rem solid #383838;
        color: #383838;
        font-weight: 400;
        height: 3rem;
        margin-left: -0.1rem;
        padding: 0;
        width: 10rem;

        &:hover {
          color: #fff;
        }

        .dot {
          @include square(0.8rem);
          border-radius: 0.4rem;
          background-color: #767B80;

          &.yellow {
            background-color: #EBBF4F;
          }
          &.red {
            background-color: #F16060;
          }
        }

        &.active {
          background-color: #383838;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }

  &:first-of-type {
    width: 100%;

    > div {
      width: 100%;

      > button {
        flex: 1;

        @include media(992px) {
          flex: unset;
        }
      }
    }
  }
  &:nth-of-type(2) > div {
    margin-left: 0;

    @include media(992px) {
      margin-left: auto;
    }

    > input {
      margin-left: 0;
    }
  }
}

.searchBlock {
  @include relative;
  margin: {
    right: auto;
    left: auto;
  };

  > svg {
    @include absolute($top: 50%, $left: 1rem);
    @include css3-prefix(transform, translateY(-50%));
  }
  > input {
    background-color: #fff;
    border-color: #383838!important;
    border-radius: 1.5rem!important;
    height: 3rem!important;
    width: 24.6rem;
    margin-left: 4px;
  }
}

.tableMapSwitch {
  flex: 1;

  > button {
    @include flexbox($ai: center, $jc: center);
    background-color: #fff;
    border: 0.1rem solid #3099FD;
    box-shadow: none;
    height: 3rem;
    padding: 0;
    width: 5rem;

    &.active {
      background-color: #3099FD;
    }

    &:first-of-type {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
}

.btnGroup {
  flex: unset;

  > button {
    @include flexbox($ai: center, $jc: center);
      // background-color: #0D1D27;
    border-radius: 0;
    height: 3rem;
    padding: 0;
    width: 5rem;

    &:hover {
      background-color: #0D1D27!important;
    }

    &:first-of-type {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
}

.mapView {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;

  border-radius: 8px;
  overflow: hidden;

  @include media(768px) {
    flex-flow: row nowrap;
    height: calc(100vh - 280px);
  }

  > * {
    width: 100%;
  }
}

.listContainer {
  @include flexbox($ai: flex-start, $fd: column);
  border: 2px solid #f6f6f6;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  order: 1;
  overflow: hidden;

  @include media(768px) {
    border: {
      top: 2px;
      right: 2px;
    }
    border-radius: 8px 0 0 8px;

    order: unset;

    height: 40rem;
    width: 240px;
  }

  > * {
    width: 100%;
  }

  .searchBlock {
    background-color: #f9f9f9;
    border-bottom: 1px solid #f6f6f6;
    padding: 8px;

    > svg {
      @include absolute($top: 50%, $left: 18px);
      @include css3-prefix(transform, translateY(-50%));
    }
    > input {
      background-color: #fff;
      border-color: #383838!important;
      border-radius: 1.5rem!important;

      margin-left: 0;
      padding-left: 30px;
      
      height: 3rem!important;
      width: 100%;
    }
  }

  .accordion {
    flex: 1;
    gap: 0;
    margin-bottom: 0;
    overflow-y: auto;

    .item {
      .header > button {
        background-color: transparent!important;
        border-bottom: 1px solid #f6f6f6;
        border-radius: 0!important;

        padding: 0!important;
        height: unset!important;
        width: 100%;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        &[aria-expanded='true'] {
          > svg {
            transform: rotate(90deg)!important;
          }
        }

        > a, span {
          order: unset;
          padding: 12px 8px;
          width: auto;

          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;

          color: #344054;
          font: 600 14px/26px 'Inter', sans-serif;

          @include media(576px) {
            font-size: 16px;
          }
          @include media(768px) {
            font-size: 14px;
          }
        }

        > svg {
          height: 12px;
          width: 12px;
          margin-right: 12px;
          transform: rotate(-90deg)!important;

          path {
            fill: #344054;
          }
        }
      }

      .monitoringDeviceList {
        padding: 0;

        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;

        .camera {
          width: 100%;

          a {
            padding: 8px 8px 8px 20px;
            width: 100%;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            &:last-of-type {
              border-bottom: 1px solid #f6f6f6;
            }

            .icon {
              background-color: #EBBF4F;
              border-radius: 50%;

              height: 20px;
              width: 20px;

              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: center;

              &.active {
                background-color: #83D75C;
              }

              svg {
                height: 15px;
                width: 15px;

                path {
                  stroke: #fff;
                }
              }
            }

            span {
              color: #344054;
              font: 500 14px/20px 'Inter', sans-serif;
            }
          }
        }
      }
    }
  }
}

.mapContainer {
  @include flexbox($ai: flex-start, $fd: column, $gap: 2rem);
  height: 30rem;
  flex-grow: 1;
  order: 0;

  @include media(768px) {
    height: calc(100vh - 280px);
  }

  .noPoles {
    @include pushAuto;
    font-weight: 600;
  }
}

.tableContainer {
  @include flexbox($ai: flex-start, $fd: column, $gap: 2rem);
}

// Marker
.marker {
  @include absolute;
  @include flexbox($ai: center, $jc: center);
  @include border-rad(30px);
  @include pady(10px);
  @include padx(13px);
  background-color: $white;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
  color: $blue;
  cursor: pointer;
  font: {
    size: 1.6rem;
    weight: 700;
  }
  height: 32px;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  &.online {
    background-color: $white;
    color: $white;
  }

  .iconNoFeed {
    @include absolute($top: -50%, $left: -10px);
  }

  .iconCount {
    @include absolute($top: -35%, $left: calc(100% - 12px));
    @include pady(3px);
    @include padx(9px);
    background-color: $red;
    border-radius: 12px;
    color: $white;
    font-weight: normal;
  }
}