// file: client/src/components/Loader/styles.module.scss

@import '../../scss/imports';

#loader {
  @include fixed( 0, 0, 0, 0, 1001 );
  @include flexbox( $fd: column, $jc: space-around );
  @include font( $inter, 10px, 600 );
  color: $gray182;
  letter-spacing: .2rem;

  &::after {
    @include fixed( 0, 0, 0, 0, 1 );
    @include pseudo();
    background-color: rgb(255 255 255 / 75%);
  }

  .loaderContain {
    @include fixed( $top: 50%, $left: 50%, $z: 2 );
    @include css3-prefix( transform, translate(-50%,-50%) );
    text-align: center;
  }

  svg {
    @include flexbox;
    height: auto;
    margin: auto;
    width: 10rem;
  }
}

.loader {
  margin-right: auto;
  margin-left: auto;
}