.container {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  color: #383838;
  gap: 8px;
}

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.legendLine {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid;
  width: 16px;
  height: 0px;
  display: block;
}
