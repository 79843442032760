@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.logoIcons {
  @include flexbox($gap: 20px);

  > span {
    @include border($width: 2px, $color: $navy);
    @include border-rad(8px);
    @include flexbox($jc: center);
    @include square(68px);

    > svg {
      @include css3-prefix(transform, scale(1.65));
    }
  }
}