@import '../../../scss/imports';

.expandContainer {
  @include absolute($bottom: 6rem, $left: 1rem);
  @include flexbox($ai: flex-start, $fd: column, $gap: 2rem);
    background-color: #fff;
    border-radius: 0.4rem;
    color: black;
    height: 30rem;
    right: 1rem;

    @include media(992px) {
      height: 40rem;
      max-width: 900px;
    }

    .header {
      @include flexbox($jc: space-between);
      background-color: #29313B;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
      height: 3.2rem;
      padding-right: 1.5rem;
      padding-left: 2rem;
      width: 100%;

      > h3 {
        @include font($size: 12px, $weight: 700);
        color: #fff;
        margin: 0;
      }

      > button {
        background-color: transparent;
        border: none;
      }
    }

    .filterRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: {
          right: 2rem;
          left: 2rem;
        };
        width: calc(100% - 4rem);

        .filterRowItem {
          @include relative;

          &.search {
            > svg {
              @include absolute($top: 50%, $left: 1rem);
              @include css3-prefix(transform, translateY(-50%));
            }
            > input {
              border-color: #383838;
              border-radius: 1.5rem;
              height: 3rem;
              padding-left: 3rem;
              width: 24.6rem;
            }
          }
        }

        .statusFilters {
            display: flex;

            > button {
              @include flexbox($jc: center, $gap: 1rem);
              @include font($size: 12px);
              background-color: #fff;
              border: 0.1rem solid #383838;
              color: #383838;
              height: 3rem;
              margin-left: -0.1rem;
              padding: 0;
              width: 10rem;

              &:first-of-type {
                border-radius: 0.4rem 0 0 0.4rem;
              }
              &:last-of-type {
                border-radius: 0 0.4rem 0.4rem 0;
              }

              &.active {
                background-color: #383838;
                color: #fff;
                font-weight: 700;
              }

              .dot {
                @include square(0.8rem);
                background-color: #767B80;
                border-radius: 50%;

                &.yellow {
                  background-color: #EBBF4F;
                }
                &.red {
                  background-color: #F16060;
                }
              }
            }
        }
    }
}
