// file: ./scss/abstracts/_mixins.scss

@import './functions';


/// Mixin backdrop-filter
/// 
/// @param {string} $type [blur]
///   - blur, brightness, contrast, drop-shadow, grayscale, hue-rotate, invert, opacity, sepia, saturate
///   - @link https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter#syntax
/// @param {string} $num [0px]

@mixin backdrop-filter( $type: blur, $num: 0px ) {
  -webkit-backdrop-filter: setupFilter( $type, $num );
  backdrop-filter: setupFilter( $type, $num );
}


/// Mixin border
/// 
/// @param {string} $width [1px]
/// @param {string} $style [solid]
///   - none, hidden, dotted, dashed, solid, double, groove, ridge, inset, outset
/// @param {string} $color [$black]
/// @param {string} $el [all]
///   - all, top, right, bottom, left, y, x

@mixin border( $el: null, $width: null, $style: solid, $color: null ) {
  border-width: 0;
  border-style: $style;
  border-color: $color;

  @if $el == null         { border-width: $width; }
  @else if $el == top     { border-top-width: $width; }
  @else if $el == right   { border-right-width: $width; }
  @else if $el == bottom  { border-bottom-width: $width; }
  @else if $el == left    { border-left-width: $width; }
  @else if $el == y {
    border-top-width: $width;
    border-bottom-width: $width;
  }
  @else if $el == x {
    border-right-width: $width;
    border-left-width: $width;
  }
  @else { border-width: 0; }
}

    /// Alias mixin for border-top
    /// Serves as an alias for `border(top, ...)`
    /// @param {argslist} $args
    /// @require {mixin} border
    
    @mixin bd-top( $args... ) {
      @include border( top, $args... );
    }

    /// Alias mixin for border-right
    /// Serves as an alias for `border(right, ...)`
    /// @param {argslist} $args
    /// @require {mixin} border
    
    @mixin bd-right( $args... ) {
      @include border( right, $args... );
    }

    /// Alias mixin for border-bottom
    /// Serves as an alias for `border(bottom, ...)`
    /// @param {argslist} $args
    /// @require {mixin} border
    
    @mixin bd-bottom( $args... ) {
      @include border( bottom, $args... );
    }

    /// Alias mixin for border-left
    /// Serves as an alias for `border(left, ...)`
    /// @param {argslist} $args
    /// @require {mixin} border
    
    @mixin bd-left( $args... ) {
      @include border( left, $args... );
    }


/// Mixin border-rad
/// 
/// @param {string} $radius [0px]

@mixin border-rad( $radius: 0px ) {
  background-clip: padding-box;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}


/// Mixin box-shadow
/// 
/// @param {string} $left [0px] - offset x
/// @param {string} $top [0px] - offset y
/// @param {string} $blur [0px] - blur radius
/// @param {string} $color [transparent]
/// @param {boolean} $inset [false]

@mixin box-shadow( $left: 0px, $top: 0px, $blur: 0px, $color: transparent, $inset: false ) {
  @if $inset
    {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    }
  @else
    {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
}


/// Mixin css3-prefix
/// 
/// Add vendor prefixes to css property
/// @param {string} $prop
/// @param {string} $val

@mixin css3-prefix( $prop, $val ) {
  -webkit-#{$prop}: #{$val};
  -moz-#{$prop}: #{$val};
  -ms-#{$prop}: #{$val};
  -o-#{$prop}: #{$val};
  #{$prop}: #{$val};
}


/// Mixin flexbox
/// 
/// @param {string} $ai [center]
///   - center, start, end, flex-start, flex-end, self-start, self-end
/// @param {string} $fd [row]
///   - row, row-reverse, column, column-reverse
/// @param {string} $jc [start]
///   - center, start, end, flex-start, flex-end, left, right
/// @param {string} $fw [nowrap]
///   - nowrap, wrap, wrap-reverse

@mixin flexbox( $ai: center, $fd: row, $jc: flex-start, $fw: nowrap, $gap: null ) {
  align-items: $ai;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: $fd;
  flex-wrap: $fw;
  gap: $gap;
  justify-content: $jc;
}


/// Mixin font
/// 
/// @param {string} $family [$inter]
/// @param {string} $size [14px] - px only
/// @param {string} $weight [400]
///   - normal, bold, lighter, bolder, 100, 200, 300, 400, 500, 600, 700, 800, 900

// @mixin font( $family: $inter, $size: 14px, $weight: 400 ) {
@mixin font( $family: 'Inter', $size: 14px, $weight: 400 ) {
  font-family: $family;
  font-size: $size;
  font-size: calculateRem( $size );
  font-weight: $weight;
  line-height: calculateLineHeight( $size);
}


/// Mixin font-size
/// @param {string} $size [14px] - px only

@mixin font-size( $size: 14px ) {
  font-size: $size;
  font-size: calculateRem( $size );
}


/// Mixin media
/// 
/// Setting media queries; allows dev to set breakpoint
/// @param {string} $size - breakpoint for media query
/// @param {args} @content - style rules

@mixin media($size) {
  @media screen and (min-width: $size) { @content; }
}


/// Mixin opacity
/// 
/// Creates opacity and fallbacks for IE
/// @param {number} $opacity [1]

@mixin opacity( $opacity: 1 ) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}


/// Mixin placeholder
/// 
/// Creates support for browsers
/// @param {args} @content - style rules

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/// Mixin pad
/// @param {string} $top    - [null]
/// @param {string} $right  - [null]
/// @param {string} $bottom - [null]
/// @param {string} $left   - [null]

@mixin pad($top: null, $right: null, $bottom: null, $left: null) {
  padding: {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}
@mixin padx($num: null) {
  padding: {
    right: $num;
    left: $num;
  }
}
@mixin pady($num: null) {
  padding: {
    top: $num;
    bottom: $num;
  }
}
@mixin padxy($num: 0) {
  padding: {
    top: $num;
    right: $num;
    bottom: $num;
    left: $num;
  }
}

/// Mixin position
/// 
/// @param {string} $pos [relative]
///   - static, relative, absolute, fixed, sticky
/// @param {string} $top [null]
/// @param {string} $right [null]
/// @param {string} $bottom [null]
/// @param {string} $left [null]
/// @param {number} $z [1]

@mixin position ( $pos: relative, $top: null, $right: null, $bottom: null, $left: null, $z: 1 ) {
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z;
}

    /// Alias mixin for absolute positioning
    /// Serves as an alias for `position(absolute, ...)`
    /// @param {Arglist} $args - Offsets
    /// @require {mixin} position

    @mixin absolute( $args... ) {
      @include position( absolute, $args... );
    }

    /// Alias mixin for relative positioning
    /// Serves as an alias for `position(relative, ...)`
    /// @param {Arglist} $args - Offsets
    /// @require {mixin} position

    @mixin relative( $args... ) {
      @include position( relative, $args... );
    }

    /// Alias mixin for fixed positioning
    /// Serves as an alias for `position(fixed, ...)`
    /// @param {Arglist} $args - Offsets
    /// @require {mixin} position

    @mixin fixed( $args... ) {
      @include position( fixed, $args... );
    }

    /// Alias mixin for static positioning
    /// Serves as an alias for `position(static)`
    /// @require {mixin} position

    @mixin static() {
      @include position( static );
    }


/// Mixin pushAuto
/// 
/// Center block element w/out top/btm margins

@mixin pushAuto {
  margin: {
    left: auto;
    right: auto;
  }
}


/// Mixin pseudo
/// 
/// Used with ::before / ::after
/// @param {string} $display [block]
///   - block, inline, inline-block, flex, inline-flex, grid, inline-grid, flow-root
///   - none, contents, table, table-row, list-item
/// @param {string} $pos [absolute]
///   - static, relative, absolute, fixed, sticky
/// @param {string} $content ['']

@mixin pseudo( $display: block, $pos: absolute, $content: '' ) {
  content: $content;
  display: $display;
  position: $pos;
}


/// Mixin for square
/// 
/// @param {string} $num [null]

@mixin square( $num: null ) {
  height: $num;
  width: $num;
}


/// Mixin for truncate
/// 
/// @param {string} $overflow [ellipsis]
///   - clip, ellipsis, initial, inherit, [string]

@mixin truncate( $overflow: ellipsis ) {
  overflow: hidden;
  text-overflow: $overflow;
  white-space: nowrap;
}


/// Mixin for unstyled-list
/// 
/// @param {Number} $pl [0] = padding-left

@mixin unstyled-list( $pl: 0 ) {
  list-style: none;
  padding-left: $pl;
}


/// Mixin vert-center
/// 
/// Vertically center element

@mixin vert-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}