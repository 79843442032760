// ./styles/_mixins.scss

// FUNCTIONS ----------

// calculateRem
// -- this will calculate and return
// -- a rem font-size based on the pixel given

@function calculateRem( $size ) {
  $remSize: $size / 10px;
  
  @return $remSize * 1rem;
}


// MIXINS ----------

// border-rad
// -- creates border-radius

@mixin border-rad( $radius ) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

// border-top-rad
// -- single side border-rad

@mixin border-top-rad( $radius ) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-btm-rad( $radius ) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-right-rad( $radius ) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  background-clip: padding-box;
}
@mixin border-left-rad( $radius ) {
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-top-left-rad( $radius ) {
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-top-right-rad( $radius ) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin border-btm-left-rad( $radius ) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-btm-right-rad( $radius ) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  background-clip: padding-box;
}


// font
// -- set px font-size and
// -- create rem font-size using function,
// -- set family & weight

@mixin font( $family, $size, $weight: 400 ) {
  font-family: $family;
  font-size: $size;
  font-size: calculateRem( $size );
  font-weight: $weight;
}


// font-size
// -- set px font-size and
// -- create rem font-size using function

@mixin font-size( $size ) {
  font-size: $size;
  font-size: calculateRem( $size );
}


// opacity
// -- creates opacity and fallbacks for IE

@mixin opacity( $opacity ) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}


// placeholder
// -- creates support for browsers

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


// position
// -- position with t/r/b/l

@mixin position ( $pos: relative, $top: null, $right: null, $bottom: null, $left: null, $z: 1 ) {
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z;
}


// push auto
// -- center block element w/out top/btm margins

@mixin pushAuto {
  margin: {
    left: auto;
    right: auto;
  }
}


// pseudo
// -- when using ::before / :: after

@mixin pseudo( $display: block, $pos: absolute, $content: '' ) {
  content: $content;
  display: $display;
  position: $pos;
}


// responsive mixin
// -- mixin for setting media queries
// -- allows dev to set breakpoint

@mixin media($size) {
  @media screen and (min-width: $size) { @content; }
}


// vendor prefixes
// -- add vendor prefixes to css property

@mixin css3-prefix( $prop, $val ) {
  -webkit-#{$prop}: #{$val};
  -moz-#{$prop}: #{$val};
  -ms-#{$prop}: #{$val};
  -o-#{$prop}: #{$val};
  #{$prop}: #{$val};
}


// vert-center
// -- vertically center element

@mixin vert-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}