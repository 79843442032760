.headerContainer {
  display: flex;
  flex-direction: row;
  color: #383838;
  align-items: center;
  justify-content: space-between;
  color: #383838;
  margin-bottom: 12px;
}

.container {
  display: flex;
  flex-direction: row;
  color: #383838;
  gap: 4px;
  height: 24px;
}

.bunkBlock {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: #F6F6F6;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #D6D6D6;
  margin: 1px;
  position: relative;
}

.bunkBlock:hover {
  border: 2px solid #3099FD;
  margin: 0px;
}

.bunkBlockOverlay {
  width: 34px;
  position: absolute;
  right: 0px;
  transform: translate(34px, 0px);
  transition: transform 0.3s;
  background-color: #000c;
  color: white;
  padding-left: 4px;
  font-size: 11px;
  font-weight: bold;
  pointer-events: none;
}

.bunkBlock:hover > .bunkBlockOverlay {
  transform: translate(0px, 0px);
}
