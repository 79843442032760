// file: client/src/components/forms/CustomSlider/styles.module.scss

@import '../../../scss/imports';

.switch {
    @include relative;
    display: inline-block;
    height: 2rem;
    width: 3.6rem;

    input {
        @include opacity( 0 );
        @include square( 0 );

        &:checked + .slider { background-color: #2196F3;
        
            &::before {
                @include css3-prefix( transform, translateX(1.6rem) );
            }
        }
        &:focus + .slider { box-shadow: 0 0 1px #2196F3; }
    }

    .slider {
        @include absolute( 0, 0, 0, 0, 1 );
        @include border-rad( 3.4rem );
        @include css3-prefix( transition, 0.4s );
        background-color: #4A5866;
        cursor: pointer;

        &::before {
            @include absolute( $bottom: 0.2rem, $left: 0.2rem );
            @include border-rad( 50% );
            @include css3-prefix( transition, 0.4s );
            @include square( 1.6rem );
            background-color: $white;
            content: '';
        }
    }
}
