@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

#header {
  @include fixed($top: 0, $right: 0, $left: 0, $z: 1000);
  @include flexbox($ai: center, $jc: space-between, $gap: 20px);
  background-color: $black;
  padding: {
    right: 3%;
    left: 3%;
  };
  height: 6rem;

  .logo {
    &Holder {
      @include flexbox($ai: flex-end, $fd: column, $gap: 0.5rem);
      color: $white;
      font: {
        family: $inter;
        size: 11px;
        weight: 700;
      }
      line-height: 100%;
      margin-left: auto;
      text-wrap: nowrap;

      @include media(768px) {
        align-items: flex-start;
        margin-left: 0;
      }

      @include media(992px) {
        @include font-size(18px);
        align-items: center;
        flex-direction: row;
        gap: 10px;
      }

      @include media(1200px) {
        font-size: 20px;
      }
    }

    &Icons {
      span {
        @include border-rad(.4rem);
        @include flexbox($jc: center);
        @include square(3rem);
        border: 1px solid $white;
        padding: 3px;

        @include media(992px) {
          @include square(32px);
        }

        svg {
          height: unset;
          width: 100%;
        }
      }
    }
  }
}

#nav {
  @include absolute($top: 6rem, $right: 100%, $bottom: 3rem, $z: -1);
  @include css3-prefix(transition, 0.2s ease-in-out);
  width: 100%;
  height: calc(100vh - 6rem);
  background-color: #000;
  // flex: 1;

  @include media(768px) {
    @include static;
    background-color: unset;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    height: 60px;
    width: unset;
    margin-right: auto;
  }

  ul {
    @include flexbox($ai: flex-start, $fd: column, $fw: nowrap);
    list-style: none;
    margin: 0;
    padding: 0;

    @include media(768px) {
      flex-direction: row;
      flex-wrap: nowrap;
      height: inherit;
    }

    &.scrollable {
      margin-top: 4px;
      gap: 4px;
      max-width: none!important;
      width: 100%;

      @include media(768px) {
        margin-top: 0;
        margin-left: 4px;
        max-width: 400px;
      }

      li a {
        @include media(768px) {
          padding-right: 42px;
        }
      }
    }

        &.customEls {
          margin-left: auto;
        }

    li {
      height: inherit;
      width: 100%;

      @include media(768px) {
        border-bottom: 0;
        width: unset;
      }

      a {
        @include css3-prefix(transition, .15s ease-in-out);
        @include flexbox;
        @include relative;
        background-color: #344054;
        height: inherit;
        padding: 12px 16px;
        color: #FFFFFF;
        font: 700 14px/20px "Inter", sans-serif;

        @include media(768px) {
          font-size: 12px;
          padding: 0 16px;
        }
        @include media(992px) {
          font-size: 14px;
          padding: 0 32px;
        }
        
        .cancelBtn {
          @include absolute($right: 8px);
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          transition: 0.2s ease-in-out;
          transform: scale(0.75);

          &:hover {
            background-color: rgba(255, 255, 255, 0.25);
          }
        }
      }

      &.active {

        a {
          background-color: #337FB3;
        }
      }
    }
  }

  &.opened {
    right: 0;
    z-index: 999;

    @include media(768px) {
      right: unset;
      z-index: unset;
      margin-right: auto;
    }
  }
}

.settingsBlock {
  position: fixed;
  inset: auto 100% 0 auto;
  z-index: -1;
  transition: 0.2s ease-in-out;
  width: 100%;

  &.opened {
    right: 0;
    z-index: 999;
  }

  @include media(768px) {
    position: static;
    height: 60px;
    width: 20px;
  }

  main & {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: unset;
  }
}
