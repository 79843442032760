@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  position: relative;
}

.totalHead {
  position: relative;
  top: 3.5px;
}

.container {
  display: flex;
  flex-direction: column;
}

.headerGraph {
  @include flexbox($fd: column);
  flex: 1;
  width: 100%;

  @include media(950px) {
    flex: unset;
    min-width: 450px;
  }
}

.formColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.performanceHeader {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;

  > .category {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > .inputs {
      display: flex;
      flex-direction: row;
      gap: 16px;

      > .box {
        display: flex;
        flex-direction: row;
        gap: 8px;

        > .info {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }
}

.headerInfo {
  @include flexbox($ai: flex-start, $fd: column, $gap: 20px);

  /*@include media(950px) {
    flex-direction: row;
  }
  @include media(1100px) {
    @include flexbox($ai: center, $jc: space-between, $gap: 2rem);
  }*/

  &.map {
    justify-content: flex-start;
  }

  > * {
    background-color: #f6f6f6;
    border-radius: 0.4rem;
    flex: 1;
    height: 14.5rem;
    padding: 2rem;
  }

  .stats {
    @include flexbox($ai: center, $jc: space-around, $gap: 2rem);
    // padding-left: 5rem;

    &.map {
      flex: unset;
      width: 100%;

      @include media(992px) {
        min-width: 60rem;
      }
    }

    &.health {
      // flex: unset;
      min-width: 35rem;
    }

    .stat {
      @include flexbox($ai: center, $fd: column, $gap: 0.5rem);

      .num {
        @include font($family: 'Inter', $size: 28px, $weight: 700);
        color: #383838;
        line-height: 3.2rem;
      }
      .sub {
        @include font($family: 'Inter', $size: 20px);
        line-height: 3.2rem;
      }
      .label {
        @include font($family: 'Inter', $weight: 700);
        text-align: center;
      }
    }
  }

  .graph {
    flex: unset;
    min-width: 45rem;
    width: 100%;

    @include media(1100px) {
      max-width: 60rem;
    }

    > div {
      max-width: 100%!important;

      > h3 {
        margin-bottom: 0;
      }
      > canvas {
        max-height: 8rem!important;
      }
    }
  }
}
