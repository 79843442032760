@import '../../../scss/imports';

.liveViewContainer {
  @include css3-prefix( transition, 0.15s );
  display: flex;
  flex-direction: column;
  height: unset;
  width: 100%;
  position: relative;
  
  @include media(992px) {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}

.btnExpand {
  @include flexbox($ai: center, $gap: 1rem);
  @include font($family: 'Inter', $size: 12px, $weight: 700);
  border: 0.2rem solid #3099FD;
  border-radius: 0.4rem;
  padding: 0.2rem 1.5rem;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.column {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.cameraContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.cameraTitle {
  position: absolute;
  background-color: white;
  border-radius: 16px;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
  color: #383838;
  font-weight: 600;
  left: 50%;
  padding: 4px 8px;
  top: 8px;
  transform: translate(-50%, 0%);
  user-select: none;
}
