// file: client/src/components/MobTrigger/styles.module.scss

@import '../../scss/imports';

.mobTriggerContain {
    @include flexbox;
    height: inherit;
  
    @include media(768px) { display: none; }
    
    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
      
      svg {
        @include square( 4rem );
        
        .line {
          fill: none;
          stroke: $white;
          stroke-width: 6;
          transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);        
          &.line1,
          &.line2,
          &.line3 { stroke-width: 6; }        
          &.line1,
          &.line3 { stroke-dasharray: 60 207; }
          &.line2 { stroke-dasharray: 60 60; }
        }
      }
      
      &.opened {
        svg {
          .line {
            &.line1,
            &.line2,
            &.line3 { stroke-width: 6; }
            &.line1,
            &.line3 {
              stroke-dasharray: 90 207;
              stroke-dashoffset: -134;
            }
            &.line2 {
              stroke-dasharray: 1 60;
              stroke-dashoffset: -30;
            }
          }
        }
      }
    }
  }