// file: client/src/components/forms/CustomMultiSelect/styles.module.scss

@import '../../../scss/imports';

.dropdown {
  position: absolute;
  background-color: white;
  max-height: 156px;
  overflow-y: auto;
  z-index: 1;
  user-select: none;
  box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.36);
  border-radius: 3px;
}

.dropdownOption {
  padding: 8px 32px 8px 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.dropdownOption.selected {
  padding: 8px;
}

.dropdownLabel {
  margin-right: 8px;
}

.selectLabel {
  display: none;
}
