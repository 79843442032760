@import '../scss/imports';

.page {
	display: flex;
	flex-direction: column;
	padding: 4rem;
  
  .pageContent {
    display: flex;
    flex-direction: row;

    & > div {
      width: 100%;
    }
  }
}
