// file: client/src/components/forms/CustomInput/styles.module.scss

@import '../../../scss/imports';

.options {
  padding: 8px 22px;
}

.options:hover {
  background-color: gray;
  cursor: pointer;
}

.dropdown {
  background-color: white;
  position: absolute;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.15);
}
