// file: client/src/components/CustomTable/styles.module.scss

@import '../../scss/imports';

.label {
    cursor: pointer;
    position: relative;
}

.header {
    user-select: none;
    cursor: pointer;
    @include bd-bottom( $width: 0.1rem, $color: $gray182 );
    padding: 0 3rem;
    background-color: inherit;

    @include media(992px) {
      @include font-size( 10px );
      padding-right: 2rem;// 1rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;// 1rem;
    }
    @include media(1200px) {
      @include font-size( 12px );
      padding-right: .5rem;
      padding-left: .5rem;
    }
}

.sortAsc {
    transform: rotate(180deg);
}

.table {
  @include bd-bottom( $width: 0.1rem, $color: $gray182 );
  width: 100%;
  flex-grow: 1;
  
  .thead {
    @include absolute;
    @include square( 0.1rem );
    border: none;
    clip: rect(0 0 0 0);
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;

    @include media(992px) {
      @include square( auto );
      @include static;
      margin: auto;
      overflow: visible;
    }
    
    .tr {
      display: table-row;
    }
  }

  .tbody {
    font-weight: 500;
    overflow: auto;
  }
  
  &.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: $gray246;
    background-color: $gray246;
    
    &:first-of-type {
      @include border-rad( 0.5rem 0.5rem 0 0);
      
      @include media(992px) { @include border-rad( 0 ); }
    }

    &:last-of-type {
      @include border-rad( 0 0 0.5rem 0.5rem );
      --bs-table-accent-bg: $white;
      background-color: $white;
      
      @include media(992px) {
        @include border-rad( 0 );
        --bs-table-accent-bg: $gray246;
        background-color: $gray246;
      }
    }
  }

  &.dark > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: darkgray;
    background-color: darkgray;
  }

  tr {
    // @include border( $width: 0.1rem, $color: $gray196 );
    // @include border-rad( 0.5rem );
    // @include box-shadow( 0, 0.1rem, 0.3rem, rgba(0, 0, 0, 0.1) );
    @include relative;
    background-color: $gray250;
    display: none;
    margin-bottom: 1.5rem;

    @include media(992px) {
      @include border-rad( 0 );
      background-color: transparent;
      border: none;
      box-shadow: none;
      margin-bottom: 0;
    }

    &::before {
        @include absolute( $top: 0, $bottom: 0, $left: 0, $z: 0 );
        content: '';
        background: rgb(0 0 0 / 2%);
        width: 50px;

        @include media(992px) { display: none; }
    }
    
    &.show { display: block;

      @include media(992px) { display: table-row; }
    }
    &.clickable-row { cursor: pointer;
      
      &:hover td { background-color: $blueRowHover; }
    }
      
    th,
    td {
      @include media(1300px) {
        @include font-size(10px);

        > label {
          @include media(1300px) {
            @include font-size(10px);
          }
        }
      }

      &:first-of-type {
        @include media(992px) {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
      &:last-of-type {
        @include media(992px) { padding-right: 2rem; }
      }
    }

    td {
      @include bd-bottom( $width: 0.1rem, $color: #ddd );
      @include font-size( 8px );
      --bs-table-accent-bg: transparent!important;
      
      display: block;
      padding: 0.5rem;
      text-align: right;
      transition: .15s;
      width: auto!important;

      @include media(576px) { @include font-size( 10px ); }
      @include media(992px) {
        @include font-size( 12px );
        border: 0;
        display: table-cell;
        min-height: 5rem;
        padding: 1.2rem 2rem;
        vertical-align: middle;

      }
      @include media(1200px) { padding: 1.2rem .5rem; }

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;

        @include media(992px) { display: none; }
      }
      &.actions { width: auto;

        &::before { display: none; }
      }        
      &:last-child { border-bottom: 0; }
    }
    
    &:nth-of-type(odd) td { background-color: $gray246; }
  }
  
  button {
    @include font( $inter, 12px, 700 );
    line-height: 100%;
    height: 4rem!important;
    min-width: 50px;

    @include media(992px) {
      @include font-size( 10px );
      flex: unset;
      height: 2.6rem!important;
      line-height: 100%;
    }
    
    &.devLink,
    &.tableLink { width: auto!important; }    
    &.devLink {
      @include font-size( 12px );
      height: auto!important;
    }
    &.tableLink {
      @include font-size( 10px );
      height: 2.6rem!important;
      padding-right: 1.5rem!important;
      padding-left: 1.5rem!important;
      width: 100% !important;

      @include media(576px) { width: auto!important; }
    }
    &.priorityUnmark {
      @include media(992px) {
        margin-left: auto;
        width: 10.5rem!important;
      }
    }
    &.pullCowPulled {
      @include media(992px) { width: 7.1rem!important; }
    }
    &.liveView {
      @include media(992px) { width: 7.6rem!important; }
    }
  }
}

.customTable {
  @include relative;

  &::after {
    @include absolute($right: 0, $bottom: 0, $left: 0, $z: 100);
    @include bd-bottom($width: 1px, $color: #d9d9d9);
    content: '';
    height: 0.1rem;
  }

  :global {
    .ReactVirtualized__Table__headerRow {
      @include bd-bottom($width: 1px, $color: #d9d9d9);
      gap: 0.5rem;

      .ReactVirtualized__Table__headerColumn {
        @include flexbox;
        margin: 0;
        padding: {
          left: 10px;
          right: 10px;
        };
        user-select: none;

        .ReactVirtualized__Table__headerTruncatedText {
          font: {
            size: 10px;
            weight: 700;
          };
          text-transform: none;
        }
      }
    }

    .ReactVirtualized__Table__row { gap: 0.5rem;

      // &:hover { background-color: $blue_row_new!important; }

      &.odd { background-color: $gray246; }

      &.rowHighlight {
        background-color: $blue!important;// #24ABF0 !important
        color: $white;
      }

      .ReactVirtualized__Table__rowColumn {
        @include flexbox( $ai: center );
        @include relative;
        color: #383838;
        font: {
          size: 12px;
          weight: 400;
        };
        height: 100%;
        margin: 0;
        padding: {
          left: 10px;
          right: 10px;
        };
        
        &.overflow { overflow: unset !important; }

        .btn {
          font: {
            size: 10px;
            weight: 600;
          };
          height: auto;
          line-height: 18px;
        }
      }
    }
  }

  .expand {
    background-color: $white !important;
    height: 100%;
  }
}