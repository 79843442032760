// file: client/src/components/TextInput/styles.module.scss

@import '../../scss/imports';

.errorText { color: $red; }

.errorInput { border-color: $red !important; }

.normSpan {
  @include font( $inter, 12px, 600 );
  color: $gray182;
}
