@import '../../scss/imports';

.container {
  @include flexbox(center, column, center);
  @include border(null, 1px, solid, $red);
  @include border-rad(0.2rem);
  @include padxy(4px!important);
	color: $red;
  width: 100%;

  .message {
    @include padxy(4px);
    color: $red;
    font-weight: 700;
  }
}
