// file: client/src/Producer/RoundsPage/styles.module.scss

@import '../../scss/imports';

.container {
  @include flexbox( $ai: flex-start, $fd: column );
	background-color: $black;
	color: $white;

  @include media(992px) {
    flex-direction: row;
	  height: 100%;
    overflow-y: hidden;
  }

    
    > * { height: 100%; }

    &.debug {
        @include media(768px) { flex-direction: column; }
        @include media(992px) { flex-direction: row; }

        .liveViewContainer {
            @include css3-prefix( transition, 0.15s );
            height: 17rem;
            width: 100%;
            
            @include media(375px) { height: 21rem; }
            @include media(576px) { height: 32rem; }
            @include media(992px) {
                height: 100%;
                width: calc(100vw - 61.5rem);
            }
        }
    }
}

.toolContainer > label {
  font-weight: bold;
}

.toolContainer > label > span {
  font-weight: normal;
}

.sidebarContainer {
    @include flexbox( $ai: flex-start, $fd: column );
    @include relative;
    background-color: #fff;
    flex: 1;
    overflow-y: scroll;
    width: 100%;

    @include media(992px) {
      flex: unset;
      height: 100%!important;
      width: 275px;
    }
    @include media(1100px) {
      width: 35.5rem;
    }

    > * { width: 100%; }

    .header {
      @include flexbox( $ai: flex-start, $fd: column, $jc: space-between, $gap: 1rem );
      @include relative;
      border-bottom: 0.1rem solid #D9D9D9;
      padding: 2rem;

      width: calc(100% + 4rem);

      @include media(375px) {
        @include flexbox();
      }
      @include media(992px) {
        width: 100%;
      }

      h2 {
        border: 0;
        color: #383838;
        flex: 1;
        margin: 0;
        max-width: 24rem;
        padding: 0;
        width: auto;

        @include media(992px) {
          font-size: 14px;
          line-height: 20px;
        }
        @include media(1100px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      button {
        @include font( $size: 10px, $weight: 500 );
        background-color: transparent;
        height: 2rem;
        margin-left: auto;
        padding: 0 1rem;
        text-transform: capitalize;

        @include media(768px) {
          @include border-rad;
          @include font-size( 12px );
          border: 0;
          height: auto;
          padding: 0;
          text-decoration: underline;
        }

        &:hover {
          @include media(768px) {
            background-color: transparent!important;
            color: $blueHover!important;
          }
        }
      }
    }

    .cattleheader {
      background-color: #f6f6f6;
      padding: {
        top: 0.8rem;
        bottom: 0.8rem;
      };

      > button {
        font-size: 2.8rem;
        line-height: 2.8rem;
        text-decoration: none;
        transform: rotate(45deg);
      }
    }
    
    .content {
        @include flexbox( $ai: flex-start, $fd: column, $gap: 2rem );
        background-color: #fff;
        flex: 1;
        // flex-grow: 1;
        overflow-y: scroll;//auto;
        padding : {
          right: 2rem;
          bottom: 5rem;
          left: 2rem;
          top: 2rem;
        }

        @include media(576px) { gap: 3rem; }
        @include media(992px) {
          gap: 20px;
          padding : {
            right: 10px;
            left: 10px;
          }
        }
        @include media(1100px) {
          gap: 3rem;
          padding : {
            right: 20px;
            left: 20px;
          }
        }

        > * { width: 100%; }

        .sec {
            @include flexbox( $ai: flex-start, $fd: column, $jc: flex-start, $gap: 1rem );

            @include media(992px) {
              font-size: 12px;
              line-height: 18px;
            }
            @include media(1100px) {
              font-size: 14px;
              line-height: 20px;
            }

            > * { width: 100%; }

            h3 {
                @include flexbox( $ai: flex-start, $jc: flex-start, $gap: 0.8rem );
                @include font( $size: 12px, $weight: 700 );
                margin-bottom: 0;

                svg {
                    @include square( 1.4rem );
                    
                    @include media(576px) {
                        @include square( 1.8rem );
                    }

                    path {
                      fill: #3099FD;
                    }
                }

                span {
                    border-bottom: 0.1rem solid #D9D9D9;
                    color: #383838;
                    flex-grow: 1;
                    line-height: 1.2rem;
                    padding-bottom: 0.4rem;
                }
            }

            .note {
                @include border-rad( 0.4rem );
                @include flexbox( $ai: flex-start, $fw: wrap, $jc: space-between, $gap: 1rem );
                background-color: #F6F6F6;
                padding: 1rem;

                p {
                    @include font( $size: 12px );
                    color: #383838;
                    line-height: 150%;
                    margin: 0;

                    &.name { font-weight: 700; }

                    &.n { width: 100%; }
                }
            }

            ul {
                @include flexbox( $ai: flex-start, $fd: column, $jc: flex-start, $gap: 0.3rem );
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    @include border-rad( 0.4rem );
                    @include flexbox( $jc: space-between, $gap: 1rem );
                    background-color: #F6F6F6;
                    color: #383838;
                    padding: 1rem;
                    width: 100%;
                }
            }

            button {
                background-color: transparent;
                margin-left: auto;
                width: 12rem;
            }

            &#ai {
                svg {
                    @include border-rad( 0.2rem );
                    background-color: #3099FD;
                    padding: 0.2rem;

                    path { fill: #fff; }
                }
            }

            table {
                border-bottom: 0;

                thead tr th {
                    border-bottom: 0;
                    padding-right: 0.8rem;
                    padding-bottom: 0.4rem;
                    padding-left: 0.8rem;
                
                    &:first-of-type { padding-left: 0.8rem; }
                    &:last-of-type { padding-right: 0.8rem; }
                }
                tbody tr {
                    @include relative;

                    &::after {
                        @include absolute( 0, 0, 0, 0, -1 );
                        @include square( 100% );
                        content: '';
                    }
                    &:nth-of-type(odd)::after { background-color: $navy; }
                    &:nth-of-type(even)::after { background-color: $menuBg; }

                    td {
                        background-color: transparent;
                        padding-right: 0.8rem;
                        padding-left: 0.8rem;

                        button {
                            @include border-rad( 50% );
                            @include flexbox( $jc: center );
                            @include font( $size: 12px, $weight: 700 );
                            @include square( 1.8rem!important );
                            border: 0;
                            color: rgb(255 255 255 / 50%);
                            min-width: 0;
                            padding: 0;
                            transition: 0.15s ease-in-out;

                            &:hover {
                                background-color: $red;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        &.contentCattle {
          gap: 1rem;

          .imgBlock {
            @include flexbox($fd: column, $gap: 1rem);

            > img {
              // height: auto;
              // width: 100%;
              background-color: rgb(246,246,246);
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              height: 200px;
              object-fit: contain;
              padding: 0;
              width: 100%;
            }
          }

          .seenBlock {
            @include flexbox($gap: 1rem);
            margin-top: 1rem;
          }

          .item {
            @include flexbox($ai: flex-start, $fd: column, $gap: 0.5rem);
            width: 100%;

            .labelBlock {
              @include flexbox($jc: space-between, $gap: 0.5rem);
              width: 100%;
            }
            .label {
              @include font($family: 'Inter', $size: 12px, $weight: 700);
              color: #383838;
              margin-bottom: 0;
            }
            .entry {
              color: #383838;
              font-size: 1.2rem;
            }
            .entryAlt {
              color: #383838;
              font-size: 1rem;
              line-height: 100%;
            }
            .plcBox {
              height: 7rem;
              width: 100%;
            }
            .box {
              @include flexbox($ai: flex-start, $fd: column);
              background-color: #f6f6f6;
              color: #383838;
              padding: 1rem;
              width: 100%;

              > div {
                @include flexbox($ai: center, $gap: 5px, $fw: wrap);
                border-bottom: 1px solid #fff!important;
                width: 100%;

                > div {
                  font-size: 12px!important;
                  line-height: 130%!important;

                  &:first-of-type {
                    margin-top: 2px;
                  }
                  &:nth-of-type(2) {
                    color: #383838!important;
                  }
                  &:nth-last-of-type(2) {
                    flex: 1;

                    @include media(992px) {
                      flex: unset;
                      width: 100%;
                    }
                  }
                  &:last-of-type {
                    margin-top: 0!important;

                    @include media(992px) {
                      text-align: right;
                      width: 100%;
                    }
                  }
                }
              }

              .item {
                @include flexbox($fw: wrap, $gap: 1.5rem, $jc: space-between);
                border-bottom: 0.2rem solid #fff;
                padding: {
                  top: 1rem;
                  bottom: 1rem;
                };

                &:last-of-type {
                  border-bottom: 0;
                }

                .label {
                  @include flexbox($gap: 0.7rem );

                  .dot {
                    @include square(0.8rem);
                    border-radius: 0.4rem;
                  }
                }
                .dateTime,
                .desc {
                  color: #383838;
                  font-size: 1.2rem;
                  line-height: 130%;
                }
                .desc {
                  width: 100%;
                }
                .by {
                  color: #979797;
                  font-size: 1.2rem;
                  line-height: 100%;
                  margin: {
                    top: -1rem;
                    left: auto;
                  };

                }
              }
            }
          }
        }
    }
    
    .actions {
        @include flexbox( $jc: space-between, $gap: 1rem );
        background-color: #F6F6F6;
        border-top: 0.1rem solid #D9D9D9;
        height: 75px;
        padding: 2rem;
        // position: fixed;
        // bottom: 0;
        // width: 100%;
        // z-index: 99;

        @include media(992px) {
          padding: {
            top: 20px;
            right: 10px;
            bottom: 20px;
            left: 10px;
          }
        }

        @include media(1100px) {
          padding: 2rem;
        }

        button {
            @include flexbox($jc: center, $gap: 0.5rem );
            @include font( $size: 10px, $weight: 700 );
            background-color: #fff;
            border: 0.1rem solid #3099FD!important;
            // color: #3099fd!important;
            // color: #fff!important;
            flex: 1;
            // max-width: 10rem;
            padding: 0 0.5rem;
            // width: 50%;

            @include media(375px) {
                @include font( $size: 12px, $weight: 700 );
                gap: 1rem;
                // max-width: 12rem;
                padding: 0 1rem;
            }
            @include media(992px) {
              @include flexbox($jc: space-between);
              font-size: 10px;
              height: auto;
              line-height: 14px;
              padding: 10px 5px;
            }
            @include media(1100px) {
              font-size: 12px;
              padding: 10px;
            }

            // &:first-of-type {
            //   @include media(992px) {
            //     justify-content: flex-start;
            //   }
            // }

            &.danger {
              border-color: #F16060!important;
              color: #fff!important;
              justify-content: center!important;
            }

            // &:last-of-type { justify-content: flex-end; }
        }
    }
}
