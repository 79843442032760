// home card styles

@import '../../../styles/imports';

.card {
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  // padding: 2rem;

  // @include media(768px) { padding: 4rem 2rem; }
  .innerWrapper {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto; 
  
  }
  &#apps {
    & > div:first-of-type { order: 1;
      @include media(768px) { order: unset; }
    }
    & > div:nth-of-type(2) { order: 2;
      @include media(768px) { order: unset; }
    }
    & > div:last-of-type {
      margin-bottom: 3rem;
      order: 0;

      @include media(768px) {
        margin-bottom: 0;
        order: unset;
      }
    }
  }

  &#mgmt {
    > div {
      &:first-of-type {
        > div {
          > h4 {
            margin-bottom: 3rem;

            @include media(768px) { margin-bottom: 0; }
          }
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media(768px) { height: 14rem; }
        @include media(1200px) { height: 16rem; }
      }
    }

    svg {
      height: 3rem;
      margin-bottom: 2rem;
      width: auto;

      @include media(768px) { height: 4rem; }
      @include media(992px) { height: 5rem; }
      @include media(1200px) { height: 6rem; }
    }
  }

  &#analytics {
    padding: 0 0 30rem;

    @include media(768px) { padding: 0; }

    > div {
      display: flex;

      &:first-of-type {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 30rem;
        order: 1;
        bottom: 0;
        width: 100%;
        
        @include media(768px) {
          height: 100%;
          width: 50%;
        }
      }
      &:last-of-type {
        order: 0;
        padding: 2rem;

        @include media(576px) { padding: 4rem; }
      }
    }

  }

  &#analysis {visibility: inherit;}
  &#intallIntegrate {
    > div {
      max-width: 100% !important;
    }
    flex-direction: column !important;
    h2 {
      font-size: 36px;
      line-height: 44px;
      padding-bottom: 24px;
      text-align: center;
      border: none;
    }
    
  }
  &#realtimeMonitoring{
    >div{
      max-width: 100% !important;
    }

  }
  &#instantAlerts{
    >div{
      max-width: 100% !important;
    }
  }
  &#dataDriven{
    >div{
      max-width: 100% !important;
      
    }
  }
  &#features{
    margin: 50px auto;
    padding: 150px 0;
    h3{font-size: 36px; line-height:44px; padding-bottom: 24px;}
    h4{font-size: 24px; font-weight: 600;}
    p{font-size: 18px; padding-bottom: 24px;}
    background-color: #000000;
    p{color: #C9E4F5; font-size:20px;}
    @media screen and (max-width: 576px) {
      padding: 80px 0;
    }
  }
  &#howitworks{
    margin-top: 15px;
    h2{font-size: 48px; line-height: 60px; font-weight: 600; padding-bottom: 20px; text-align: left; border: none;
    @media screen and (max-width: 576px) {
      font-size: 36px;
      line-height: 44px;
      
    }
    }
    a{font-size: 14px; font-weight: 600; color:#337FB3; padding-bottom: 20px; text-align: left; border: none; cursor: pointer;}
    a:hover{color: #000000;}
  }
  &#getNotified{
    background-color:#F9FAFB ;
    padding: 20px 0;
    form{
      margin-top: 25px;
    }
    button{
      width: 200px;
      padding: 10px 5px;
    }

  }

  &#inTheKnow {
    > div {
      &:first-of-type {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        z-index: 1;
      }
      &:nth-of-type(2) {
        background: linear-gradient(90deg, #888B8C 0%, rgba(136, 139, 140, 0.4) 45.43%, rgba(136, 139, 140, 0.7) 57.33%);
        height: 100%;
        z-index: 2;
      }
      &:last-of-type {
        padding: 2rem;

        @include media(576px) { padding: 4rem; }
      }
    }
  }
  &#footer{
    visibility: inherit;
  }

  &#ecosystem,
  &#analysis {
    padding: 0 0 30rem;
    
    @include media(768px) { padding: 0; }

    > div {
      display: flex;

      &:first-of-type {
        order: 0;
        padding: 2rem;

        @include media(576px) {
          order: unset;
          padding: 4rem;
        }
      }

      &:last-of-type {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 30rem;
        order: 1;
        bottom: 0;
        width: 100%;

        @include media(768px) {
          height: 100%;
          order: unset;
          left: 50%;
          width: 50%;
        }
      }
    }
  }

  &#developGrow,
  &#appMarketplace { @extend #analytics; }

  &#cta {
    @include media(992px) { padding: 4rem 3rem; }

    > div:last-of-type { gap: 2rem; }
  }
  &#monitors{
    // margin-top:-40px;
    background-color: #000000;
  }
  &#science{
    margin: 50px auto;
    height: 100%;
    h3{font-size: 36px; line-height:44px; padding-bottom: 24px;}
    h4{font-size: 24px; font-weight: 600;}
    p{font-size: 18px; padding-bottom: 24px;}
    p.small{font-size: 16px;}
    
  }
  &#requestdemo{
    p{padding-bottom: 24px;}

    h4{font-size:24px; margin:unset;padding-bottom: 12px;}
    button{width: 200px;}
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.8rem;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 130%;
    margin: 0 auto;

    span {
      display: inline-flex;
      font-weight: 700;
      margin: 0 auto;
      max-width: 13rem;
    }
  }

  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media(768px) {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
}