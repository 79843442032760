// file: client/src/Producer/RoundsPage/styles.module.scss

// @import '../../../scss/imports';
@import '../../../scss/abstracts/variables';
@import '../../../scss/abstracts/mixins';

.container {
    @include flexbox( $ai: flex-start, $fd: column );
    background-color: $black;
    color: $white;
    height: 100%;
    overflow-y: hidden;

    @include media(768px) { flex-direction: row; }

    
    > * { height: 100%; }
    
    .liveViewContainer {
        @include css3-prefix( transition, 0.15s );
        height: 17rem;
        width: 100%;
        display: flex;
        overflow: hidden;
        
        @include media(375px) { height: 21rem; }
        @include media(576px) { height: 32rem; }
        @include media(768px) {
            height: 100%;
            width: calc(100vw - 35.5rem);
        }
    }

    &.debug {
        @include media(768px) { flex-direction: column; }
        @include media(992px) { flex-direction: row; }

        .liveViewContainer {
            @include css3-prefix( transition, 0.15s );
            height: 17rem;
            width: 100%;
            
            @include media(375px) { height: 21rem; }
            @include media(576px) { height: 32rem; }
            @include media(992px) {
                height: 100%;
                width: calc(100vw - 61.5rem);
            }
        }
    }
}

.sidebarContainer {
    @include flexbox( $ai: flex-start, $fd: column );
    @include relative;
    background-color: #fff;
    height: calc(100% - 21rem)!important;
    width: 100%;

    @include media(576px) { height: calc(100% - 32rem)!important; }
    @include media(768px) {
        height: 100%!important;
        width: 35.5rem;
    }

    > * { width: 100%; }
    
    .content {
        @include flexbox( $ai: flex-start, $fd: column, $gap: 2rem );
        background-color: #fff;
        flex-grow: 1;
        overflow-y: auto;
        padding-right: 2rem;
        padding-bottom: 5rem;
        padding-left: 2rem;

        @include media(576px) { gap: 3rem; }

        > * { width: 100%; }

        > div {
          &:nth-child(3) {
            flex-direction: column;

            @include media(992px) {
              flex-direction: row;
            }
          }
          &:last-child {
            padding-bottom: 10px;

            > div > div > div {
              &:nth-of-type(2) {
                color: #383838!important;
                flex: 1;
                text-align: right;
              }
              &:nth-of-type(3) {
                width: 100%;
              }
              &:last-of-type {
                margin: {
                  top: 0!important;
                }
              }
            }
          }
        }

        .header {
            @include flexbox( $ai: flex-start, $fd: column, $jc: space-between, $gap: 1rem );
            @include relative;
            border-bottom: 0.1rem solid #D9D9D9;
            margin-right: -2rem;
            margin-left: -2rem;
            padding: 2rem;
            width: calc(100% + 4rem);

            @include media(375px) {
              @include flexbox();
            }

            h2 {
              color: #383838;
                border: 0;
                margin: 0;
                max-width: 24rem;
                padding: 0;
            }

            button {
                @include font( $size: 10px, $weight: 500 );
                background-color: transparent;
                height: 2rem;
                margin-left: auto;
                padding: 0 1rem;
                text-transform: capitalize;

                @include media(768px) {
                    @include border-rad;
                    @include font-size( 12px );
                    border: 0;
                    height: auto;
                    padding: 0;
                    text-decoration: underline;
                }

                &:hover {
                    @include media(768px) {
                        background-color: transparent!important;
                        color: $blueHover!important;
                    }
                }
            }
        }

        .sec {
            @include flexbox( $ai: flex-start, $fd: column, $jc: flex-start, $gap: 1rem );

            > * { width: 100%; }

            h3 {
                @include flexbox( $ai: flex-start, $jc: flex-start, $gap: 0.8rem );
                @include font( $size: 12px, $weight: 700 );
                margin-bottom: 0;

                svg {
                    @include square( 1.4rem );
                    
                    @include media(576px) {
                        @include square( 1.8rem );
                    }

                    path {
                      fill: #3099FD;
                    }
                }

                span {
                    border-bottom: 0.1rem solid #D9D9D9;
                    color: #383838;
                    flex-grow: 1;
                    line-height: 1.2rem;
                    padding-bottom: 0.4rem;
                }
            }

            .note {
                @include border-rad( 0.4rem );
                @include flexbox( $ai: flex-start, $fw: wrap, $jc: space-between, $gap: 1rem );
                background-color: #F6F6F6;
                padding: 1rem;

                p {
                    @include font( $size: 12px );
                    color: #383838;
                    line-height: 150%;
                    margin: 0;

                    &.name { font-weight: 700; }

                    &.n { width: 100%; }
                }
            }

            ul {
                @include flexbox( $ai: flex-start, $fd: column, $jc: flex-start, $gap: 0.3rem );
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    @include border-rad( 0.4rem );
                    @include flexbox( $jc: space-between, $gap: 1rem );
                    background-color: #F6F6F6;
                    color: #383838;
                    padding: 1rem;
                    width: 100%;
                }
            }

            button {
                background-color: transparent;
                margin-left: auto;
                width: 12rem;
            }

            &#ai {
                svg {
                    @include border-rad( 0.2rem );
                    background-color: #3099FD;
                    padding: 0.2rem;

                    path { fill: #fff; }
                }
            }

            table { border-bottom: 0;

                thead tr th {
                    border-bottom: 0;
                    padding-right: 0.8rem;
                    padding-bottom: 0.4rem;
                    padding-left: 0.8rem;
                
                    &:first-of-type { padding-left: 0.8rem; }
                    &:last-of-type { padding-right: 0.8rem; }
                }
                tbody tr {
                    @include relative;

                    &::after {
                        @include absolute( 0, 0, 0, 0, -1 );
                        @include square( 100% );
                        content: '';
                    }
                    &:nth-of-type(odd)::after { background-color: $navy; }
                    &:nth-of-type(even)::after { background-color: $menuBg; }

                    td {
                        background-color: transparent;
                        padding-right: 0.8rem;
                        padding-left: 0.8rem;

                        button {
                            @include border-rad( 50% );
                            @include flexbox( $jc: center );
                            @include font( $size: 12px, $weight: 700 );
                            @include square( 1.8rem!important );
                            border: 0;
                            color: rgb(255 255 255 / 50%);
                            min-width: 0;
                            padding: 0;
                            transition: 0.15s ease-in-out;

                            &:hover {
                                background-color: $red;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .actions {
        @include flexbox( $jc: space-between, $gap: 1rem );
        background-color: #F6F6F6;
        border-top: 0.1rem solid #D9D9D9;
        padding: 2rem;

        button {
            @include flexbox($jc: center, $gap: 0.5rem );
            @include font( $size: 10px, $weight: 700 );
            background-color: #fff;
            border: 0.1rem solid #3099FD!important;
            color: #3099fd!important;
            flex: 1;
            // max-width: 10rem;
            padding: 0 0.5rem;
            // width: 50%;

            @include media(375px) {
                @include font( $size: 12px, $weight: 700 );
                gap: 1rem;
                // max-width: 12rem;
                padding: 0 1rem;
            }

            &.danger {
              border-color: #F16060!important;
              color: #fff!important;
              justify-content: center!important;
            }

            &:last-of-type { justify-content: flex-end; }
        }
    }
}

.expandContainer {
  @include absolute($bottom: 6rem, $left: 1rem);
  @include flexbox($ai: flex-start, $fd: column, $gap: 2rem);
    background-color: #fff;
    border-radius: 0.4rem;
    color: black;
    height: 40rem;
    width: 93.6rem;

    .header {
      @include flexbox($jc: space-between);
      background-color: #29313B;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
      height: 3.2rem;
      padding-right: 1.5rem;
      padding-left: 2rem;
      width: 100%;

      > h3 {
        @include font($size: 12px, $weight: 700);
        color: #fff;
        margin: 0;
      }

      > button {
        background-color: transparent;
        border: none;
      }
    }

    .filterRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: {
          right: 2rem;
          left: 2rem;
        };
        width: calc(100% - 4rem);

        .filterRowItem {
          @include relative;

          &.search {
            > svg {
              @include absolute($top: 50%, $left: 1rem);
              @include css3-prefix(transform, translateY(-50%));
            }
            > input {
              border-color: #383838;
              border-radius: 1.5rem;
              height: 3rem;
              width: 24.6rem;
            }
          }
        }

        .statusFilters {
            display: flex;

            > button {
              @include flexbox($jc: center, $gap: 1rem);
              @include font($size: 12px);
              background-color: #fff;
              border: 0.1rem solid #383838;
              color: #383838;
              height: 3rem;
              margin-left: -0.1rem;
              padding: 0;
              width: 10rem;

              &:first-of-type {
                border-radius: 0.4rem 0 0 0.4rem;
              }
              &:last-of-type {
                border-radius: 0 0.4rem 0.4rem 0;
              }

              &.active {
                background-color: #383838;
                color: #fff;
                font-weight: 700;
              }

              .dot {
                @include square(0.8rem);
                background-color: #767B80;
                border-radius: 50%;

                &.yellow {
                  background-color: #EBBF4F;
                }
                &.red {
                  background-color: #F16060;
                }
              }
            }
        }
    }
}

.infoPanel {
  background-color: #F6F6F6;
  height: calc(100vh - 60px);
  max-width: 200px;
  padding: {
    right: 10px;
    left: 10px;
  }

  @include media(992px) {
    max-width: 260px;
  }
  @include media(1100px) {
    @include padx(20px);
    max-width: none;
    width: 355px;
  }

  // > div,
  .sidebarContainer {
    background-color: transparent;
    // margin: {
    //   right: 2rem;
    //   left: 2rem;
    // }
    width: 100%;

    @include media(992px) {
      max-width: 31.5rem;
      // width: 31.5rem;
    }

    // > div {// .content
    .content {
      background-color: transparent!important;
      gap: 10px;
      padding: {
        right: 0!important;
        bottom: 0!important;
        left: 0!important;
      };

      // > div {
      //   &:first-of-type {// .header        
      //     display: none!important;
      //   }
      //   &:last-of-type {// animal history

      //     > div {
      //       background-color: #fff;
      //       padding: 1rem;
      //     }
      //   }
      // }

    }
  }
}

.header {
  background-color: #F6F6F6;
  display: none;
}

.imgBlock {
  @include flexbox($ai: flex-start, $fd: column, $gap: 10px);

  img {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
    height: 184px;
    object-fit: contain;
    width: 100%;
  }

  button {
    width: 100%;
  }
}

.seenBlock {
  @include flexbox($gap: 10px);
  margin-top: 10px;

  .item {
    @include flexbox($ai: flex-start, $fd: column, $gap: 5px);
    flex: 1;

    > h4,
    > span {
      color: $gray56;
      font-size: 12px;
    }

    > h4 {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.graphBlock {
  @include flexbox($ai: flex-start, $fd: column!important, $gap: 5px);
  margin-top: 20px;

  .infoRow {
    @include flexbox($ai: flex-end, $jc: space-between, $gap: 5px);
    width: 100%;

    .label,
    .timespan {
      color: $gray56;
      line-height: 100%;
    }    
    .label {
      font: {
        size: 12px;
        weight: 700;
      }
    }
    .timespan {
      font-size: 10px;
    }
  }

  .graph {
    height: 70px;
    width: 100%;
  }
}

.animalHistory {
  margin-top: 10px;

  h3 {
    color: $gray56;
    font: {
      size: 12px;
      weight: 700;
    }
    line-height: 100%;
    margin-bottom: 10px;
  }

  .roll {
    @include padxy(10px);
    background-color: $white;
    color: $gray56;
  }
}
